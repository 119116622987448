import React, { useEffect, useState, useRef, useMemo } from "react";
import { Button } from "@nextui-org/button";
import { useRouter } from "next/router";
import { FaCaretRight, FaPlus } from "react-icons/fa";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/dropdown";
import { EventEmitter } from "./SlateEditor";
import { useFetchCategory, useFetchGrades, useFetchHierarchy } from "./api/cms";
import { AiFillCloseSquare } from "react-icons/ai";
import ScrollableContent from "./ScrollableComponent";
import { GrPowerReset } from "react-icons/gr";

function BreadCrumbItem(props: any) {
  const [newItemName, setNewItemName] = useState("");
  const items = useMemo(() => {
    let a = props.items.map((c) => ({
      ...c,
      onClick: () => {
        props.onChange(c);
      },
      className: "",
    }));

    if (props.onAddNew && !props.readonly)
      a = [
        ...a,
        {
          id: "add-new-item",
          name: (
            <div className="flex items-center gap-2 text-xs">
              <FaPlus />
              <span>Add New Item</span>
            </div>
          ),
          onClick: () => {
            props.onAddNew();
          },
          className: "bg-[rgb(128,22,21)] text-white p-2",
        },
      ];

    return [
      ...a,
      {
        id: "deselect-item",
        name: (
          <div className="flex items-center gap-2 text-xs">
            <GrPowerReset />
            <span>Reset</span>
          </div>
        ),
        onClick: () => {
          props.onReset();
        },
        className: "bg-gray-700 text-white p-2",
      },
    ];
  }, [props]);

  if (props.showInputField && !props.readonly) {
    const handleSubmit = (e) => {
      e.preventDefault();
      props.onAddNew(newItemName);
      setNewItemName("");
    };
    return (
      <form
        onSubmit={handleSubmit}
        className="flex items-stretch flex-shrink-0"
      >
        <input
          type="text"
          value={newItemName}
          onChange={(e) => setNewItemName(e.target.value)}
          placeholder="Enter new item"
          className="border-none text-sm px-3 p-1"
        />
        <button
          className="text-xs text-white p-2 h-full bg-blue-500"
          type="submit"
        >
          Add
        </button>
        <button
          className="text-[16px] text-white p-2 h-full bg-red-500"
          onClick={() => {
            props.onRemoveInput();
          }}
        >
          <AiFillCloseSquare />
        </button>
      </form>
    );
  }

  return (
    <Dropdown
      placement="bottom-start"
      classNames={{
        content: "!rounded-sm !p-0 overflow-hidden !min-w-[120px] !bg-gray-50",
        base: "max-h-[350px] overflow-auto shadow-sm",
      }}
      offset={1}
    >
      <DropdownTrigger className="!px-0 text-sm bg-transparent border-t-0 border-l-0 border-r-0 flex items-stretch justify-center !h-[auto]">
        <Button className="border-b md:border-none border-b-gray-200 flex-shrink-0 flex items-stretch !gap-0 rounded-none breadcrumb-trigger">
          <div className=" flex-1 md:w-auto md:overflow-[unset] overflow-hidden w-full py-1 flex items-center px-3">
            {props.items.find((c) => c.id === props.activeKey)?.name ? (
              <span className="whitespace-pre-wrap md:whitespace-nowrap text-left">
                {props.items.find((c) => c.id === props.activeKey)?.name}
              </span>
            ) : (
              <span className="opacity-30 whitespace-pre-wrap md:whitespace-nowrap text-left">
                {props.placeholder}
              </span>
            )}
          </div>
          <div
            className=" bg-gray-500 py-2 px-1 border-l border-r flex items-center justify-center"
            // @ts-ignore
            style={{ "--tw-bg-opacity": 0.15 }}
          >
            <FaCaretRight className="caret-icon transition-all" />
            {/* <FaCaretDown /> */}
          </div>
        </Button>
      </DropdownTrigger>
      {/* <DropdownTrigger className="text-sm !py-1 px-2.5 pl-3 border bg-gray-50 rounded flex items-center justify-center gap-2 !h-[auto]">
        <Button>
          {props.items.find((c) => c.id === props.activeKey)?.label ? (
            <span>
              {props.items.find((c) => c.id === props.activeKey)?.label}
            </span>
          ) : (
            <span className="text-black text-opacity-30">
              {props.placeholder}
            </span>
          )}
          <FaCaretDown />
        </Button>
      </DropdownTrigger> */}
      <DropdownMenu
        aria-label="Example with disabled actions"
        classNames={{
          base: "!p-0",
        }}
      >
        {items.map((item) => (
          <DropdownItem
            className={"!rounded-none !text-sm " + (item.className ?? "")}
            classNames={{ base: "!rounded-none" }}
            key={item.id}
            onClick={() => {
              item.onClick();
            }}
          >
            {item.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export function updateHierarchyOnSelectedLevels(
  selectedLevels: LevelItem[],
  _hierarchyData: {
    id: string;
    name: string;
    slug: string;
    children: LevelItem[];
  }
) {
  const hierarchyData = JSON.parse(JSON.stringify(_hierarchyData));
  // Helper function to find and update the hierarchy
  function updateHierarchy(
    hierarchy: LevelItem[],
    levels: LevelItem[],
    levelIndex = 0
  ) {
    if (levelIndex === levels.length) return;

    const currentLevel = levels[levelIndex];
    let nextLevel = hierarchy.find((level) => level.slug === currentLevel.slug);

    if (!nextLevel) {
      // If the level doesn't exist, create it using the selectedLevels information
      nextLevel = {
        id: currentLevel.id,
        name: currentLevel.name,
        slug: currentLevel.slug,
        children: [],
        has_article: false,
      };
      hierarchy.push(nextLevel);
    }

    // If it's the last level (i.e., the article creation level), mark it with has_article: true
    if (levelIndex === levels.length - 1) {
      nextLevel.has_article = true;
    }

    // Recursively continue down the hierarchy if there are more levels to process
    updateHierarchy(nextLevel.children, levels, levelIndex + 1);
  }

  // Update the hierarchy using the selectedLevels array
  updateHierarchy(hierarchyData.children, selectedLevels);

  // Set the updated hierarchy

  return hierarchyData;
}

interface LevelItem {
  id: string;
  name: string;
  slug: string;
  children: LevelItem[];
  has_article?: boolean;
  status?: "published" | "unpublished";
}

function mergeHierarchies(
  hierarchy1: LevelItem[],
  hierarchy2: LevelItem[]
): LevelItem[] {
  const mergedHierarchy: LevelItem[] = [...hierarchy1];

  // Iterate over each element in hierarchy2 and try to merge it into hierarchy1
  hierarchy2.forEach((item2) => {
    const existingItem = mergedHierarchy.find(
      (item1) => item1.id === item2.id || item1.slug === item2.slug
    );

    if (existingItem) {
      // If the item exists in both hierarchies, recursively merge their children
      existingItem.children = mergeHierarchies(
        existingItem.children,
        item2.children
      );
    } else {
      // If the item does not exist, simply add it to the merged hierarchy
      mergedHierarchy.push(item2);
    }
  });

  return mergedHierarchy;
}

export function formSelectedLevelsFromHierarchy(hierarchy, selectedPath) {
  const selectedLevels: LevelItem[] = [];
  let currentHierarchyLevel = hierarchy;

  for (const slug of selectedPath) {
    // Find the current level in the hierarchy that matches the slug
    const nextLevel = currentHierarchyLevel.children.find(
      (level) => level.slug === slug
    );

    if (!nextLevel) {
      break;
      // throw new Error(`Level with slug ${slug} not found in hierarchy`);
    }

    // Add the found level to the selectedLevels array
    selectedLevels.push({
      id: nextLevel.id,
      name: nextLevel.name,
      slug: nextLevel.slug,
      children: nextLevel.children,
    });

    // Move to the next level's children for the next iteration
    currentHierarchyLevel = nextLevel;
  }

  return selectedLevels;
}

export default function BreadCrumb({
  selectionPath: defaultSelectionPath,
  selectionLevels: defaultSelectionLevels,
  selectedGrade: defaultSelectedGrade,
  noRouteChange,
  onRouteChange,
  readOnly,
  dontAllowCreateItem,
}: {
  selectionPath?: string[];
  selectionLevels?: any;
  selectedGrade?: LevelItem;
  noRouteChange?: any;
  onRouteChange?: any;
  readOnly?: boolean;
  dontAllowCreateItem?: boolean;
}) {
  const router = useRouter();
  // const [selectedLevels, setSelectedLevels] = useState<LevelItem[]>([]);
  const changedRef = useRef(false);
  const [addingLevelIndex, setAddingLevelIndex] = useState(null);
  const { data: grades } = useFetchGrades();
  const [currentGrade, setCurrentGrade] = useState<LevelItem | null>(null);
  const { data } = useFetchCategory({ grade_id: currentGrade?.id });
  const { data: _hierarchy } = useFetchHierarchy({
    grade_id: currentGrade?.id,
  });
  const [selectionPath, setSelectionPath] = useState<string[]>([]);
  const containerRef = useRef<any>(null);

  // Mock data representing the hierarchy structure
  const [hierarchyData, setHierarchyData] = useState<LevelItem>({
    id: "class_9",
    name: "Class 9",
    slug: "class-9",
    children: [],
  });

  // Function to derive selected levels from the path
  const selectedLevels = useMemo(() => {
    if (!hierarchyData || !selectionPath) return [];
    return formSelectedLevelsFromHierarchy(hierarchyData, selectionPath);
  }, [hierarchyData, selectionPath]);

  const gradeItems = useMemo(() => {
    return (
      grades?.map((gradeItem) => ({
        id: gradeItem.id,
        name: gradeItem.label,
        slug: gradeItem.label.toLowerCase().replace(/\s+/g, "-"),
        children: [],
      })) ?? []
    );
  }, [grades]);

  useEffect(() => {
    if (!data || !_hierarchy || !currentGrade || readOnly) return () => {};

    const merged = mergeHierarchies(
      JSON.parse(JSON.stringify(data)),
      JSON.parse(JSON.stringify(_hierarchy.children))
    );

    console.log("setHierarchyData - ", setHierarchyData);
    setHierarchyData({
      id: currentGrade.id,
      name: currentGrade?.name,
      slug: currentGrade?.slug,
      children: merged,
    });
  }, [data, _hierarchy, currentGrade, readOnly]);

  useEffect(() => {
    // if (defaultSelectionLevels) {
    //   setSelectedLevels(defaultSelectionLevels);
    // }
    if (defaultSelectedGrade) {
      setCurrentGrade(defaultSelectedGrade);
    }
    if (defaultSelectionPath) {
      setSelectionPath(defaultSelectionPath);
    }
  }, [defaultSelectedGrade, defaultSelectionPath]);

  useEffect(() => {
    if (_hierarchy) {
      console.log("setHierarchyData - ", setHierarchyData);
      readOnly && setHierarchyData(JSON.parse(JSON.stringify(_hierarchy)));
    } else {
      if (currentGrade) {
        console.log("setHierarchyData - ", setHierarchyData);
        setHierarchyData({
          id: currentGrade.id,
          name: currentGrade?.name,
          slug: currentGrade?.slug,
          children: [],
        });
      }
    }
  }, [_hierarchy, currentGrade, readOnly]);

  // Emit the selection change event
  useEffect(() => {
    // if (!_hierarchy || !currentGrade) return;

    EventEmitter.emit("crumb-selection-change", {
      selectionPath,
      defaultHierarchy: hierarchyData,
      gradeLevel: currentGrade,
    });

    if (!changedRef.current || noRouteChange) return;

    const url = selectionPath.map((slug) => `/${slug}`).join("");

    if (onRouteChange && currentGrade) {
      return onRouteChange(currentGrade?.id + url);
    }

    if (url !== router.asPath && currentGrade) {
      const path = currentGrade?.id + url;
      if (readOnly) router.push("/" + path, undefined, { shallow: false });
      else router.push("/blog?path=" + path, undefined, { shallow: true });
      changedRef.current = false;
    }
  }, [
    router,
    noRouteChange,
    onRouteChange,
    hierarchyData,
    currentGrade,
    readOnly,
    selectionPath,
  ]);

  // Handle selection changes dynamically
  const handleChange = (levelIndex, newValue) => {
    changedRef.current = true;

    setAddingLevelIndex(null);

    setSelectionPath((prevPath) => {
      const newPath = [...prevPath];
      newPath[levelIndex] = newValue.slug;
      return newPath.slice(0, levelIndex + 1);
    });
    containerRef.current?.scrollToRight();

    // setSelectedLevels((prevSelectedLevels) => {
    //   const newSelectedLevels = [...prevSelectedLevels];
    //   newSelectedLevels[levelIndex] = newValue;

    //   // Clear all levels deeper than the current one
    //   return newSelectedLevels.slice(0, levelIndex + 1);
    // });
  };

  const handleAddNew = (levelIndex, newItemName) => {
    if (newItemName) {
      const newItem = {
        id: newItemName.toLowerCase().replace(/\s+/g, "_"),
        name: newItemName,
        slug: newItemName.toLowerCase().replace(/\s+/g, "-"),
        children: [],
      };

      // Update the hierarchy data to include the new item at the correct level

      setHierarchyData((prevHierarchyData) => {
        const updatedHierarchy = { ...prevHierarchyData };

        // Recursively find the correct level to insert the new item
        let currentLevel = updatedHierarchy;
        for (let i = 0; i < levelIndex; i++) {
          const selectedItem = selectedLevels[i];
          // @ts-ignore
          currentLevel = currentLevel.children.find(
            (item) => item.id === selectedItem.id
          );
        }

        // Add the new item to the current level
        currentLevel.children.push(newItem);

        return updatedHierarchy;
      });

      changedRef.current = true;

      // Update selectionPath to include the newly added item
      setSelectionPath((prevPath) => {
        const newPath = [...prevPath];
        newPath[levelIndex] = newItem.slug;
        return newPath.slice(0, levelIndex + 1); // Clear all levels after this one
      });

      setAddingLevelIndex(null);

      containerRef.current?.scrollToRight();

      return;
    }
    setAddingLevelIndex(levelIndex); // Show input field for adding new item

    containerRef.current?.scrollToRight();
  };

  // Recursively render each level of the breadcrumb
  const renderBreadcrumbItems = (data, levelIndex) => {
    const activeItem = selectedLevels[levelIndex];

    return (
      <>
        <BreadCrumbItem
          key={levelIndex}
          items={data.children}
          activeKey={activeItem?.id}
          onChange={(newValue) => handleChange(levelIndex, newValue)}
          placeholder={`Select Item`}
          onAddNew={
            dontAllowCreateItem
              ? undefined
              : (newItemName) => handleAddNew(levelIndex, newItemName)
          }
          onReset={() => {
            changedRef.current = true;
            setSelectionPath((prevPath) => prevPath.slice(0, levelIndex));
          }}
          showInputField={addingLevelIndex === levelIndex}
          onRemoveInput={() => setAddingLevelIndex(null)}
        />
        {!dontAllowCreateItem &&
          activeItem &&
          activeItem?.children.length === 0 && (
            <BreadCrumbItem
              key={levelIndex}
              items={[]} // No children, so pass an empty array
              activeKey={null} // No active key since it's a new level
              placeholder="Select or Add New Item"
              onAddNew={(newItemName) =>
                handleAddNew(levelIndex + 1, newItemName)
              } // Add to the next level
              showInputField={addingLevelIndex === levelIndex + 1}
              onRemoveInput={() => setAddingLevelIndex(null)}
            />
          )}
        {activeItem &&
          activeItem.children.length > 0 &&
          renderBreadcrumbItems(activeItem, levelIndex + 1)}
      </>
    );
  };

  return (
    <ScrollableContent
      classNames={{
        container: "breadcrumb-container",
      }}
      ref={containerRef}
    >
      <div className="md:flex-row md:w-auto w-full flex-col items-stretch inline-flex border md:items-center justify-start border-e-0">
        <BreadCrumbItem
          items={gradeItems ?? []}
          activeKey={currentGrade?.id}
          onChange={(newValue) => {
            setCurrentGrade(newValue);
            setSelectionPath([]);
            onRouteChange && onRouteChange(newValue.id);
          }}
          onReset={() => {
            setCurrentGrade(null);
            setSelectionPath([]);
            onRouteChange && onRouteChange("");
          }}
          placeholder={`Select Grade`}
        />
        {renderBreadcrumbItems(hierarchyData, 0)}
      </div>
    </ScrollableContent>
  );
}
