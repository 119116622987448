import React from "react";
import ChooseChapter from "./ChooseChapter";
// import { Chapter } from "@/store/cms";
import { useState } from "react";
import { Chapter } from "./cms";
import Link from "next/link";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/modal";
import { Button } from "@nextui-org/button";
import { ReactEditor, useSlate } from "slate-react";
import { IoMdAdd } from "react-icons/io";
import { Transforms } from "slate";

export default function ChooseChapterThumbnail({ attributes, element }: any) {
  const [chapter, setChapter] = useState<Chapter | undefined | null>(null);
  const editor = useSlate();
  const handleChapterChange = (chapter: Chapter | undefined) => {
    setChapter(chapter);
  };
  const { isOpen, onClose, onOpenChange, onOpen } = useDisclosure();

  const handleChangeThumbnail = () => {
    // @ts-ignore
    const path = ReactEditor.findPath(editor, element);
    // Adjust the path to point to the root
    const rootPath = path;

    // Replace the current element with the custom element
    let customElement: any = {
      type: "chapter",
      thumbnail_url: chapter?.illustration_art,
      hex_color: chapter?.hex_color,
      chapter_name: chapter?.chapter_name,
      chapter_id: chapter?.chapter_id,
      children: [{ text: "" }], // Make sure to add children, as Slate expects all nodes to have children
    };

    Transforms.setNodes(editor, customElement, { at: rootPath });

    onClose();
  };

  return (
    <div {...attributes}>
      {/* <Link href={"/"} className="inline-block max-w-full"> */}
      {!element.thumbnail_url && (
        <div
          onClick={() => {
            onOpen();
          }}
          className="text-center flex-col p-3 flex text-gray-300 cursor-pointer items-center justify-center gap-4 aspect-video max-h-[300px] border border-dashed"
        >
          <div className="flex items-center justify-center">
            <IoMdAdd className="text-[50px]" />
          </div>
          <p>Click to Choose Thumbnail</p>
        </div>
      )}
      {element.thumbnail_url && (
        <Link
          className="inline-block max-w-full"
          href={`/guest_classroom?subject=${
            element.chapter_id?.split("_")[3]
          }&chapter=${element.chapter_id}`}
          target="_blank"
        >
          <div
            style={{ background: element?.hex_color }}
            className="aspect-video flex items-center justify-center w-[400px] max-w-full rounded-lg overflow-hidden"
          >
            <img
              className="w-full h-full object-contain"
              src={element?.thumbnail_url}
            />
          </div>
          <p className="text-center mt-2 text-sm font-semibold">
            {element?.chapter_name}
          </p>
        </Link>
      )}
      {/* </Link> */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onOpenChange={onOpenChange}
        classNames={{
          wrapper: "bg-black bg-opacity-50 !items-center ",
          base: "min-h-[400px] overflow-auto !max-h-[70vh] w-[90vw] max-w-[500px] !bg-white rounded",
          closeButton:
            "text-black text-sm p-1 bg-gray-300 absolute right-2 top-2",
        }}
      >
        <ModalContent className="max-h-[90vh]">
          <ModalHeader>
            <span>Choose Chapter Thumbnail</span>
          </ModalHeader>
          <ModalBody className="p-0 h-full py-4 px-6">
            <div>
              <ChooseChapter onChapterSelect={handleChapterChange} />
              {chapter && (
                <>
                  <hr className="my-5" />
                  <div>
                    <div>
                      <h3 className="mb-2 ml-1 text-xs font-bold text-gray-500">
                        Thumbnail Preview
                      </h3>
                    </div>
                    <div>
                      <div
                        onClick={() => {
                          onOpen();
                        }}
                        style={{ background: chapter?.hex_color }}
                        className="aspect-video mx-auto flex items-center justify-center w-[400px] max-w-full rounded-lg overflow-hidden"
                      >
                        <img
                          className="w-full h-full object-contain"
                          src={chapter?.illustration_art}
                        />
                      </div>
                      <p className="text-center mt-2 text-sm font-semibold">
                        {chapter?.chapter_name}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* <Link href={"/"} className="inline-block max-w-full">
              <div
                style={{ background: "rgb(215, 246, 183)" }}
                className="aspect-video flex items-center justify-center w-[400px] max-w-full rounded-lg overflow-hidden"
              >
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_functionalgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_functionalgrammar_directindirectspeech%2Fmain_image.jpg?alt=media&token=a2012260-431e-4681-b3a8-37e7cf699a7e"
                  }
                />
              </div>
              <p className="text-center mt-2 text-sm font-semibold">
                Fundamentals of Physics
              </p>
            </Link> */}
          </ModalBody>
          <ModalFooter>
            <div>
              <Button
                className="primary__btn text-sm px-8"
                onClick={handleChangeThumbnail}
              >
                Save Thumbnail
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
