import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
// @ts-ignore
import styles from "./ScrollableComponent.module.css";

interface ScrollableContentProps {
  children: ReactNode;
  classNames?: {
    wrapper?: string;
    container?: string;
    content?: string;
  };
  styles?: {
    wrapper?: React.CSSProperties;
    container?: React.CSSProperties;
    content?: React.CSSProperties;
  };
}

const ScrollableContentRef = (
  { children, classNames, styles: _styles }: ScrollableContentProps,
  ref: any
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [shadowLeft, setShadowLeft] = useState(false);
  const [shadowRight, setShadowRight] = useState(false);

  const handleScroll = () => {
    if (!containerRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    setShadowLeft(scrollLeft > 0);
    setShadowRight(scrollLeft < scrollWidth - clientWidth);
  };

  useImperativeHandle(ref, () => ({
    scrollToRight: () => {
      containerRef.current?.scrollTo({
        left: containerRef.current.scrollWidth + 100,
        behavior: "smooth",
      });
    },
  }));

  useEffect(() => {
    if (!containerRef.current) return;
    setTimeout(() => {
      handleScroll(); // Initial check
    }, 500);
    containerRef.current.addEventListener("scroll", handleScroll);

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div
      className={styles.wrapper + " " + (classNames?.wrapper ?? "")}
      style={_styles?.wrapper}
    >
      <div
        className={`${styles.shadow} ${shadowLeft ? styles.visible : ""} ${
          styles.left
        }`}
      />
      <div
        className={`${styles.shadow} ${shadowRight ? styles.visible : ""} ${
          styles.right
        }`}
      />
      <div
        className={styles.container + " " + (classNames?.container ?? "")}
        ref={containerRef}
      >
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

const ScrollableContent = forwardRef(ScrollableContentRef);

export const ScrollableVerticalContent = ({
  children,
  className,
  classNames,
}: {
  children: ReactNode;
  className?: string;
  classNames?: {
    wrapper?: string;
    container?: string;
    content?: string;
  };
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [shadowTop, setShadowTop] = useState(false);
  const [shadowBottom, setShadowBottom] = useState(false);

  const handleScroll = () => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    setShadowTop(scrollTop > 0);
    setShadowBottom(scrollTop < scrollHeight - clientHeight);
  };

  useEffect(() => {
    if (!containerRef.current) return;
    handleScroll(); // Initial check
    containerRef.current.addEventListener("scroll", handleScroll);

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div
      className={
        styles.vertical_wrapper + " " + className + " " + classNames?.wrapper ??
        ""
      }
    >
      <div
        className={`${styles.shadow} ${shadowTop ? styles.visible : ""} ${
          styles.top
        }`}
      />
      <div
        className={`${styles.shadow} ${shadowBottom ? styles.visible : ""} ${
          styles.bottom
        }`}
      />
      <div
        className={
          styles.vertical_container +
            " " +
            className +
            " " +
            classNames?.container ?? ""
        }
        ref={containerRef}
      >
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default ScrollableContent;
