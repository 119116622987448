// /* eslint-disable @typescript-eslint/no-explicit-any */

import { Descendant } from "slate";
import { CustomElement } from "../../types/slate";

export interface LevelItem {
  id: string;
  name: string;
  slug: string;
  children: LevelItem[];
  has_article?: boolean;
  level_index?: number;
  url?: string;
  status?: "published" | "unpublished";
  grade_id?: string;
}

interface CategoryOptions {
  category_image_url: string | null;
  category_name: string;
  generic_name: string;
  id: string;
  serial_order: number;
  skippable?: boolean;
  _meta: Subject[];
}

export interface Chapter {
  illustration_art: string;
  serial_order: number;
  description: string;
  generic_name: string;
  lecture_item_count: number;
  chapter_name: string;
  chapter_id: string;
  hex_color: string;
  chapter_number: number;
}

interface Subject {
  illustration_art?: string;
  main_thumbnail_url: string;
  description: string;
  subject_id: string;
  updated_on: number;
  subject_name: string;
  chapters: Chapter[];
  serial_order: number;
  generic_name: string;
  hex_color: string;
}

export function insertLevelNames(_path: string[]): string[] {
  const path = ["articles", ..._path];
  const transformedPath: string[] = [];

  for (let i = 0; i < path.length; i++) {
    // Push the current path element
    transformedPath.push(path[i]);

    // Insert a level name after the element, but skip adding a level after the last element
    if (i < path.length - 1) {
      const levelNumber = i + 1; // Start level numbering from 1
      transformedPath.push(`level_${levelNumber}`);
    }
  }

  return transformedPath;
}

export class Category {
  readonly name: string;
  readonly id: string;
  readonly serial_order: number;
  readonly skippable?: boolean;
  readonly subjects?: Subject[];

  constructor(options: CategoryOptions) {
    this.name = options.category_name;
    this.id = options.id;
    this.serial_order = options.serial_order;
    this.skippable = options.skippable;
    this.subjects = options._meta;
  }

  static async fetchCategories({ grade_id }: { grade_id: string }) {
    const querySnapshot = await require("../../firebase-config")
      .db.collection(`cms_data/${grade_id}/scope/${grade_id}_learn/category`)
      .get();

    const _docs = querySnapshot.docs;

    const _categories: Category[] = [];

    for (let i = 0; i < _docs.length; i++) {
      const doc = _docs[i];
      const snapshot = await require("../../firebase-config")
        .db.doc(doc.ref.path)
        .withConverter(categoryConverter)
        .get();

      const _data = snapshot.data();

      if (_data) _categories.push(_data);
    }

    return _categories;
  }

  static getChaptersBySubjectId(subject_id: string, categories: Category[]) {
    for (const category of categories) {
      const subject = category.subjects?.find(
        (s) => s.subject_id === subject_id
      );
      if (subject) {
        return subject.chapters;
      }
    }
    return undefined;
  }

  static getCategoryById(category_id: string, categories: Category[]) {
    return categories.find((category) => category.id === category_id);
  }

  static getSubjectById(subject_id: string, categories: Category[]) {
    for (const category of categories) {
      const subject = category.subjects?.find(
        (subject) => subject.subject_id === subject_id
      );
      if (subject) {
        return subject;
      }
    }
    return undefined;
  }

  static getChapterById(chapter_id: string, categories: Category[]) {
    for (const category of categories) {
      for (const subject of category.subjects || []) {
        const chapter = subject.chapters.find(
          (chapter) => chapter.chapter_id === chapter_id
        );
        if (chapter) {
          return chapter;
        }
      }
    }
    return undefined;
  }

  static transformCategoriesToLevels(categories: Category[]): LevelItem[] {
    return categories.map((category) => {
      const categorySlug = category.name.toLowerCase().replace(/\s+/g, "-");

      const transformedCategory = {
        id: category.id,
        name: category.name,
        slug: categorySlug,
        children:
          category.subjects?.map((subject) => {
            const subjectSlug = subject.subject_name
              .toLowerCase()
              .replace(/\s+/g, "-");

            return {
              id: subject.subject_id,
              name: subject.subject_name,
              slug: subjectSlug,
              children: subject.chapters.map((chapter) => {
                const chapterSlug = chapter.chapter_name
                  .toLowerCase()
                  .replace(/\s+/g, "-");

                return {
                  id: chapter.chapter_id,
                  name: chapter.chapter_name,
                  slug: chapterSlug,
                  children: [], // Assuming chapters don't have further children
                };
              }),
            };
          }) || [],
      };

      return transformedCategory;
    });
  }
}

export function insertLevelsAndGetFirestorePath(path: string[]): {
  collectionPath: string;
  docId: string;
} {
  const transformedPath: string[] = [];

  // Insert level_X between elements
  for (let i = 0; i < path.length; i++) {
    transformedPath.push(path[i]);
    if (i < path.length - 1) {
      transformedPath.push(`level_${i + 1}`);
    }
  }

  // Ensure the collection path has an odd number of elements
  const pathLength = transformedPath.length;

  let collectionPath: string;
  let docId: string;

  if (pathLength % 2 === 0) {
    // If the length is even, treat the last item as the docId
    docId = transformedPath[pathLength - 1];
    collectionPath = transformedPath.slice(0, pathLength - 1).join("/");
  } else {
    // If the length is odd, treat the last item as the docId
    docId = transformedPath[pathLength - 1];
    collectionPath = transformedPath.slice(0, pathLength - 1).join("/");
  }

  return { collectionPath: "articles/" + collectionPath, docId };
}

export function transformToFirestorePathWithLevels(str: string): string {
  const parts = str.split("/");
  const transformedPath: string[] = ["articles"]; // Start with 'articles'

  parts.forEach((part, index) => {
    transformedPath.push(part);

    // Insert level_X after each element, but not after the last one
    if (index < parts.length - 1) {
      transformedPath.push(`level_${index + 1}`);
    }
  });

  return transformedPath.join("/");
}

export function fetchRelatedArticlesFromHierarchy({
  hierarchy,
  selectionPath, // Path of the current article
  currentArticleSlug,
  selectedGrade,
}) {
  let currentLevel = hierarchy;

  const selectionParentPath = selectionPath.slice(0, -1);

  // Traverse the hierarchy to find the current level
  for (const slug of selectionParentPath) {
    currentLevel = currentLevel.children.find((level) => level.slug === slug);
    if (!currentLevel) {
      throw new Error(`Level with slug "${slug}" not found in hierarchy.`);
    }
  }

  // Generate URL for each article by traversing up to the current level
  const generateUrlForArticle = (articleSlug) => {
    // Combine all parent slugs and the article slug into a URL
    const fullPath = [...selectionParentPath, articleSlug].join("/");
    return `/${selectedGrade.id}/${fullPath}`; // Prefix with '/' for the root path
  };

  console.log("currentArticleSlug - ", currentArticleSlug, currentLevel);

  // Check if the current level contains articles
  if (currentLevel.children) {
    // Return articles from the current level, excluding the current article
    return currentLevel.children
      .filter((article) => article.slug !== currentArticleSlug)
      .map((article) => ({
        ...article,
        url: generateUrlForArticle(article.slug),
      }));
  }

  // If no articles found at the current level, return an empty array
  return [];
}

export function toggleArticleStatus(
  hierarchyData: LevelItem[], // Hierarchy structure
  selectionPath: string[], // Path to the article to be published/unpublished
  currentStatus: "published" | "unpublished" // Current status
): LevelItem[] {
  // Helper function to traverse the hierarchy
  function traverseAndUpdate(
    hierarchy: LevelItem[],
    path: string[],
    levelIndex: number
  ) {
    if (levelIndex === path.length) return;

    const currentSlug = path[levelIndex];
    const currentLevel = hierarchy.find((level) => level.slug === currentSlug);

    if (!currentLevel) {
      throw new Error(`Level with slug "${currentSlug}" not found.`);
    }

    // If we reach the last level (the article), toggle its status
    if (levelIndex === path.length - 1) {
      currentLevel.status = currentStatus;
      currentLevel.has_article = currentLevel.status === "published"; // Optional: sync has_article with status
    } else {
      // Recursively traverse down the hierarchy
      traverseAndUpdate(currentLevel.children, path, levelIndex + 1);
    }
  }

  // Clone the hierarchy to avoid direct mutations
  const updatedHierarchy = JSON.parse(JSON.stringify(hierarchyData));

  // Start traversing the hierarchy
  traverseAndUpdate(updatedHierarchy, selectionPath, 0);

  return updatedHierarchy; // Return updated hierarchy
}

interface ArticleOptions {
  nodes: CustomElement[];
  // id: string;
  selectionLevels: LevelItem[];
  selectedGrade: LevelItem;
  selectionPath: string[];
}
export class Article {
  readonly nodes: CustomElement[];
  // readonly id: string;
  readonly selectionLevels: LevelItem[];
  readonly selectedGrade: LevelItem;
  readonly selectionPath: string[];

  constructor(options: ArticleOptions) {
    // this.id = options.id;
    this.nodes = options.nodes;
    this.selectedGrade = options.selectedGrade;
    this.selectionLevels = options.selectionLevels;
    this.selectionPath = options.selectionPath;
  }

  static getFirestorePath(urlPath: string[]) {
    const pathLength = urlPath.length;

    // Determine Firestore collection path based on URL segments
    let collectionPath = "";
    let docId = "";

    switch (pathLength) {
      case 1: // Only gradeId
        collectionPath = `articles`;
        docId = urlPath[0]; // gradeId
        break;
      case 2: // gradeId and categoryId
        collectionPath = `articles/${urlPath[0]}/categories`;
        docId = urlPath[1]; // categoryId
        break;
      case 3: // gradeId, categoryId, and subjectId
        collectionPath = `articles/${urlPath[0]}/categories/${urlPath[1]}/subjects`;
        docId = urlPath[2]; // subjectId
        break;
      case 4: // gradeId, categoryId, subjectId, and chapterId
        collectionPath = `articles/${urlPath[0]}/categories/${urlPath[1]}/subjects/${urlPath[2]}/chapters`;
        docId = urlPath[3]; // chapterId
        break;
      default:
        throw new Error(
          "Invalid path length. Path must have between 1 and 4 elements."
        );
    }

    return { collectionPath, docId };
  }

  static async fetchByUrl(url: string) {
    const docPath = transformToFirestorePathWithLevels(url);

    const snapshot = await require("../../firebase-config")
      .db.doc(docPath)
      .withConverter(articleConverter)
      .get();

    if (!snapshot.exists) {
      throw new Error("Document not found");
    }

    return snapshot.data() as Article | undefined;
  }

  static async toggleStatus(
    grade_id: string,
    url: string,
    targetStatus: "published" | "unpublished"
  ) {
    const docPath = transformToFirestorePathWithLevels(url);

    const articleRef = require("../../firebase-config").db.doc(docPath);

    console.log("docPath - ", docPath);

    const articleSnapshot = await articleRef.get();

    if (!articleSnapshot.exists) {
      throw new Error("Article not found.");
    }

    const articleData = articleSnapshot.data();

    if (!articleData) {
      throw new Error("Article data not found.");
    }

    const updatedArticleData = {
      ...articleData,
      status: targetStatus,
    };

    await articleRef.set(updatedArticleData);

    const snapshot = await require("../../firebase-config")
      .db.collection("articles")
      .doc(grade_id)
      .get();

    const hierarchy = snapshot.data()?.hierarchy as LevelItem;

    const updatedHierarchy = toggleArticleStatus(
      hierarchy.children,
      articleData.selectionPath,
      targetStatus
    );

    await require("../../firebase-config")
      .db.collection("articles")
      .doc(grade_id)
      .set(
        {
          hierarchy: {
            ...hierarchy,
            children: updatedHierarchy,
          },
        },
        { merge: true }
      );

    // Update the hierarchy Data

    return updatedArticleData;
  }
}

export const articleConverter = {
  toFirestore: () => {
    throw new Error("Action not allowed.");
    return {};
  },
  fromFirestore: (snapshot: any) => {
    if (!snapshot.exists) return undefined;
    const data = snapshot.data();

    return new Article(data);
  },
};

export const categoryConverter = {
  toFirestore: () => {
    throw new Error("Action not allowed.");
    return {};
  },
  fromFirestore: (snapshot: any) => {
    if (!snapshot.exists) return undefined;
    const data = snapshot.data();

    return new Category(data);
  },
};
