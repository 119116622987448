import React, { useEffect } from "react";
import {} from "./cms";
import { Chapter } from "./cms";
import { Button } from "@nextui-org/button";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/dropdown";
import { Select, SelectItem } from "@nextui-org/select";
import { useMemo, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";

export const dummyCategories = [
  {
    name: "English Basics",
    id: "class_9_learn_englishbasics",
    serial_order: 6,
    skippable: false,
    subjects: [
      {
        subject_name: "Reading",
        description:
          "The art of reading quickly and understanding correctly is the art to succeed in life. So let's get to it!",
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishreading%2Fillustration.jpg?alt=media&token=8b3a61b1-749d-41c7-84b0-2f871852e5ee",
        updated_on: 1628851328804,
        main_thumbnail_url:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishreading%2Fmain_image.jpg?alt=media&token=71fee776-46cb-41d6-95f7-5371c991d88b",
        generic_name: "reading",
        subject_id: "class_9_learn_englishbasics_englishreading",
        serial_order: 1,
        hex_color: "#bbd2e9",
        chapters: [
          {
            chapter_id:
              "class_9_learn_englishbasics_englishreading_discursivepassage",
            chapter_number: 5,
            serial_order: 5,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishreading%2Fchapter%2Fclass_9_learn_englishbasics_englishreading_discursivepassage%2Fillustration.png?alt=media&token=ac32c37e-796c-4406-af1f-f2ebc015854e",
            chapter_name: "Discursive Passage",
            hex_color: "#999899",
            lecture_item_count: 6,
            generic_name: "discursive passage",
            description:
              "Lets decode a passage loaded with perspectives step by step!",
          },
          {
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishreading%2Fchapter%2Fclass_9_learn_englishbasics_englishreading_factualpassages%2Fillustration.png?alt=media&token=59557f8a-663b-45a2-9012-ef81e8a52784",
            chapter_id:
              "class_9_learn_englishbasics_englishreading_factualpassages",
            generic_name: "factual passages",
            description:
              "Learn how to extract the right information from a passage full of facts!",
            chapter_number: 4,
            lecture_item_count: 6,
            chapter_name: "Factual Passages",
            serial_order: 4,
            hex_color: "#edf3fb",
          },
          {
            lecture_item_count: 1,
            hex_color: "#fceae9",
            serial_order: 2,
            chapter_id:
              "class_9_learn_englishbasics_englishreading_formatofreading",
            description:
              "Learn how to transform your ideas into a well structured composition",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishreading%2Fchapter%2Fclass_9_learn_englishbasics_englishreading_formatofreading%2Fillustration.png?alt=media&token=24b29679-360a-46ed-8bc2-1ed70dca38a1",
            chapter_name: "Format of Reading",
            chapter_number: 2,
            generic_name: "format of reading",
          },
          {
            generic_name: "introduction to reading",
            chapter_id:
              "class_9_learn_englishbasics_englishreading_introductiontoreading",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishreading%2Fchapter%2Fclass_9_learn_englishbasics_englishreading_introductiontoreading%2Fillustration.png?alt=media&token=5214ab51-cd49-4dd3-a86a-0dc194f29471",
            hex_color: "#eaf2fc",
            chapter_number: 1,
            chapter_name: "Introduction to Reading",
            description: "Text is all around us. Let's learn to read it!",
            lecture_item_count: 1,
            serial_order: 1,
          },
          {
            chapter_id:
              "class_9_learn_englishbasics_englishreading_strategydevelopment",
            chapter_number: 3,
            serial_order: 3,
            description: "Tricks and tips to conquer your next exam!",
            hex_color: "#505254",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishreading%2Fchapter%2Fclass_9_learn_englishbasics_englishreading_strategydevelopment%2Fillustration.png?alt=media&token=b5a77f93-05ee-46a3-8952-77bc7f307155",
            lecture_item_count: 1,
            generic_name: "strategy development",
            chapter_name: "Strategy Development",
          },
        ],
      },
      {
        subject_name: "Writing",
        main_thumbnail_url:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishwriting%2Fmain_image.jpg?alt=media&token=ee05ceb0-83fd-45dd-a480-a9f4b4cfdd75",
        description:
          "In the smartphone era, we write as much as we talk. The subject helps us master putting our imagination to words.",
        subject_id: "class_9_learn_englishbasics_englishwriting",
        serial_order: 2,
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishwriting%2Fillustration.jpg?alt=media&token=46906c52-d82c-45c4-aa5e-eff0fbe8b453",
        chapters: [
          {
            serial_order: 4,
            description:
              "Discover the art of reaching a mass audience with your words!",
            chapter_name: "Article Writing",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishwriting%2Fchapter%2Fclass_9_learn_englishbasics_englishwriting_articlewriting%2Fillustration.png?alt=media&token=4ac322a4-b3cb-40a8-9d28-697f3ae99973",
            chapter_number: 4,
            hex_color: "#d5d5d5",
            generic_name: "article writing",
            lecture_item_count: 8,
            chapter_id:
              "class_9_learn_englishbasics_englishwriting_articlewriting",
          },
          {
            lecture_item_count: 8,
            hex_color: "#ddf7fe",
            chapter_number: 6,
            chapter_id: "class_9_learn_englishbasics_englishwriting_diaryentry",
            serial_order: 6,
            description: "Your personal space that only you have access to.",
            generic_name: "diary entry",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishwriting%2Fchapter%2Fclass_9_learn_englishbasics_englishwriting_diaryentry%2Fillustration.png?alt=media&token=6c6213c8-d8ab-4f08-bcea-f0c345c1e140",
            chapter_name: "Diary Entry",
          },
          {
            lecture_item_count: 1,
            chapter_number: 2,
            serial_order: 2,
            hex_color: "#c4defe",
            chapter_name: "Format of Writing",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishwriting%2Fchapter%2Fclass_9_learn_englishbasics_englishwriting_formatofwriting%2Fillustration.png?alt=media&token=2b005276-8665-4fb4-9627-92dee4190bc0",
            generic_name: "format of writing",
            description:
              "uncover the rules of writing and different syles of writing.",
            chapter_id:
              "class_9_learn_englishbasics_englishwriting_formatofwriting",
          },
          {
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishwriting%2Fchapter%2Fclass_9_learn_englishbasics_englishwriting_introductiontowriting%2Fillustration.png?alt=media&token=91de5df9-9e13-4236-b95f-5d8146278edb",
            generic_name: "introduction to writing",
            description: "Lets exercise our minds and understand how to write!",
            serial_order: 1,
            lecture_item_count: 1,
            chapter_name: "Introduction to Writing",
            hex_color: "#505254",
            chapter_number: 1,
            chapter_id:
              "class_9_learn_englishbasics_englishwriting_introductiontowriting",
          },
          {
            description: "craft your own masterpiece and engage the reader.",
            serial_order: 5,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishbasics%2Fsubject%2Fclass_9_learn_englishbasics_englishwriting%2Fchapter%2Fclass_9_learn_englishbasics_englishwriting_storywriting%2Fillustration.png?alt=media&token=36c253de-92c7-41ea-9237-3a2154fc1e52",
            chapter_name: "Story Writing",
            hex_color: "#e1d6d6",
            chapter_number: 5,
            lecture_item_count: 8,
            chapter_id:
              "class_9_learn_englishbasics_englishwriting_storywriting",
            generic_name: "story writing",
          },
        ],
        updated_on: 1624397304702,
        generic_name: "writing",
        hex_color: "#bbd2e9",
      },
    ],
  },
  {
    name: "Grammar",
    id: "class_9_learn_englishgrammar",
    serial_order: 5,
    skippable: false,
    subjects: [
      {
        hex_color: "#bbd2e9",
        serial_order: 1,
        subject_id: "class_9_learn_englishgrammar_basicgrammar",
        subject_name: "Basic Grammar",
        updated_on: 1625037518183,
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fmain_image.jpg?alt=media&token=afd12c23-4cf8-4957-872a-cabe7011e55e",
        description: "",
        generic_name: "basic grammar",
        chapters: [
          {
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_adjectives",
            description:
              "Adjectives are words that describe nouns (or pronouns).",
            hex_color: "#EAC0AD",
            serial_order: 3,
            chapter_name: "Adjectives",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_adjectives%2Fmain_image.jpg?alt=media&token=249b2bf7-d260-4925-95ed-63492f1973b3",
            generic_name: "adjectives",
            lecture_item_count: 11,
            chapter_number: 3,
          },
          {
            serial_order: 12,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_adverbs%2Fmain_image.jpg?alt=media&token=63d6339a-adf4-4f55-a685-4ecc88f2e948",
            description:
              "These modify a verb, an adjective, or another adverb. Ex: Quick-ly!",
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_adverbs",
            chapter_name: "Adverbs",
            hex_color: "#DBBFEF",
            lecture_item_count: 9,
            chapter_number: 12,
            generic_name: "adverbs",
          },
          {
            serial_order: 14,
            chapter_name: "Conjunction",
            description:
              "Words that link other words, phrases, or clauses together.",
            generic_name: "conjunction",
            chapter_number: 14,
            hex_color: "#D7EBFF",
            lecture_item_count: 10,
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_conjunction",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_conjunction%2Fmain_image.jpg?alt=media&token=74369609-9106-4572-a4c8-63066e37c157",
          },
          {
            chapter_number: 4,
            hex_color: "#FFD8E5",
            description: "Words placed in front of a noun to specify quantity.",
            chapter_name: "Determiners",
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_determiners",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_determiners%2Fmain_image.jpg?alt=media&token=dcc0eb2e-18d0-46d0-9bdf-a02af3f8b88f",
            lecture_item_count: 18,
            generic_name: "determiners",
            serial_order: 4,
          },
          {
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_gerund%2Fmain_image.jpg?alt=media&token=0bb0fc55-0c5a-4cee-a1d0-e35f64bb87c9",
            chapter_number: 8,
            generic_name: "gerund",
            lecture_item_count: 9,
            chapter_name: "Gerund",
            hex_color: "#E6CBFE",
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_gerund",
            description:
              "Form that is derived from a verb, but functions as a noun.",
            serial_order: 8,
          },
          {
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_infinitives%2Fmain_image.jpg?alt=media&token=0e50c03c-0fdf-44b7-bbe7-0e9830020982",
            chapter_name: "Infinitives",
            hex_color: "#D3EDC6",
            chapter_number: 7,
            lecture_item_count: 12,
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_infinitives",
            generic_name: "infinitives",
            description:
              "Formed from a verb, but does not act like it. Interesting!",
            serial_order: 7,
          },
          {
            generic_name: "interjections",
            chapter_id:
              "class_9_learn_englishgrammar_basicgrammar_interjections",
            description:
              "Word added to a sentence to convey an emotion or a sentiment.",
            hex_color: "#CFF5EE",
            serial_order: 15,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_interjections%2Fmain_image.jpg?alt=media&token=a57fc9fc-7abc-4669-a8fb-1b0ed4c01d3d",
            chapter_name: "Interjections",
            chapter_number: 15,
            lecture_item_count: 6,
          },
          {
            lecture_item_count: 14,
            hex_color: "#FFBDD0",
            chapter_number: 11,
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_modals",
            description:
              "Verb used to express: ability, possibility, permission or obligation.",
            serial_order: 11,
            generic_name: "modals",
            chapter_name: "Modals",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_modals%2Fmain_image.jpg?alt=media&token=886797d3-0f07-45aa-84f7-c20fa85bb376",
          },
          {
            chapter_number: 1,
            chapter_name: "Nouns",
            hex_color: "#D3F1FA",
            lecture_item_count: 21,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_nouns%2Fmain_image.jpg?alt=media&token=affd9311-98dc-4ac3-a1b7-1eeeb78ed853",
            generic_name: "nouns",
            description:
              "Used to identify people, places, or things. Interesting!",
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_nouns",
            serial_order: 1,
          },
          {
            description: "A word formed from a verb, but used as an adjective.",
            chapter_number: 9,
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_participles",
            lecture_item_count: 9,
            hex_color: "#B4E2F6",
            serial_order: 9,
            chapter_name: "Participles",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_participles%2Fmain_image.jpg?alt=media&token=46e9196d-eb65-4f78-b174-eb5f0c33df4c",
            generic_name: "participles",
          },
          {
            chapter_name: "Preposition",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_preposition%2Fmain_image.jpg?alt=media&token=e231edfb-fa17-4514-a841-d31e2cb8bcdb",
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_preposition",
            description:
              "Word that tells where something is in relation to something else.",
            serial_order: 13,
            hex_color: "#FCE5BD",
            lecture_item_count: 18,
            generic_name: "preposition",
            chapter_number: 13,
          },
          {
            description:
              "Used in place of a proper noun (like someone’s name)!",
            chapter_name: "Pronouns",
            serial_order: 2,
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_pronouns",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_pronouns%2Fmain_image.jpg?alt=media&token=43d98d85-8aa3-4ea3-a5b8-4f9875356ce2",
            lecture_item_count: 8,
            hex_color: "#BFE6E7",
            chapter_number: 2,
            generic_name: "pronouns",
          },
          {
            generic_name: "subject verb agreement",
            chapter_id:
              "class_9_learn_englishgrammar_basicgrammar_subjectverbagreement",
            description: "Relationship and agreement between Subject and Verb!",
            chapter_number: 6,
            chapter_name: "Subject Verb Agreement",
            serial_order: 6,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_subjectverbagreement%2Fmain_image.jpg?alt=media&token=f48cfd7c-b978-409d-927a-f228ae05ab6d",
            hex_color: "#FFDCE4",
            lecture_item_count: 15,
          },
          {
            chapter_number: 10,
            generic_name: "tenses",
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_tenses",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_tenses%2Fmain_image.jpg?alt=media&token=fb2c3996-1d69-48ad-a5b1-fe519fd1d52f",
            serial_order: 10,
            lecture_item_count: 18,
            description:
              "Method that we use to refer to time - past, present and future.",
            chapter_name: "Tenses",
            hex_color: "#FDCCB8",
          },
          {
            hex_color: "#FFD8A3",
            serial_order: 5,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_basicgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_basicgrammar_verbs%2Fmain_image.jpg?alt=media&token=9d02380c-20f1-4db8-9f8c-3cf6bc2eb95c",
            chapter_name: "Verbs",
            generic_name: "verbs",
            lecture_item_count: 19,
            chapter_number: 5,
            description: "Words that express physical or mental actions.",
            chapter_id: "class_9_learn_englishgrammar_basicgrammar_verbs",
          },
        ],
      },
      {
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_functionalgrammar%2Fmain_image.jpg?alt=media&token=aa078189-d9bb-4101-9f07-20b7533e07fa",
        updated_on: 1625050102139,
        subject_id: "class_9_learn_englishgrammar_functionalgrammar",
        generic_name: "functional grammar",
        hex_color: "#bbd2e9",
        subject_name: "Functional Grammar",
        description: "",
        serial_order: 2,
        chapters: [
          {
            chapter_name: "Active - Passive Voice",
            hex_color: "#C3E1FF",
            generic_name: "active - passive voice",
            chapter_number: 3,
            lecture_item_count: 21,
            description:
              "Changing a sentences because subject is no longer active.",
            chapter_id:
              "class_9_learn_englishgrammar_functionalgrammar_activepassivevoice",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_functionalgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_functionalgrammar_activepassivevoice%2Fmain_image.jpg?alt=media&token=8ecf2c64-c2ea-402f-81b2-180b19e1f45e",
            serial_order: 3,
          },
          {
            description:
              "Adding quotation marks or not makes all the difference!",
            serial_order: 4,
            chapter_name: "Direct Indirect Speech",
            chapter_number: 4,
            generic_name: "direct indirect speech",
            lecture_item_count: 24,
            chapter_id:
              "class_9_learn_englishgrammar_functionalgrammar_directindirectspeech",
            hex_color: "#D7F6B7",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_functionalgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_functionalgrammar_directindirectspeech%2Fmain_image.jpg?alt=media&token=a2012260-431e-4681-b3a8-37e7cf699a7e",
          },
          {
            description:
              "Presence of subject and verb makes a clause, absence makes a phrase.",
            chapter_number: 6,
            chapter_name: "Phrases and Clauses",
            serial_order: 6,
            chapter_id:
              "class_9_learn_englishgrammar_functionalgrammar_phrasesclauses",
            generic_name: "phrases & clauses",
            hex_color: "#C8EDE3",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_functionalgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_functionalgrammar_phrasesclauses%2Fmain_image.jpg?alt=media&token=6721bf95-e617-4ef5-989c-9d5973116861",
            lecture_item_count: 11,
          },
          {
            description:
              "Tools used to make the emotion behind a statement clear to the reader.",
            lecture_item_count: 11,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_functionalgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_functionalgrammar_punctuation%2Fmain_image.jpg?alt=media&token=371b3dd9-c82e-4407-b205-aa875eb0e630",
            chapter_number: 5,
            chapter_id:
              "class_9_learn_englishgrammar_functionalgrammar_punctuation",
            hex_color: "#E2CBFF",
            serial_order: 5,
            generic_name: "punctuation",
            chapter_name: "Punctuation",
          },
          {
            hex_color: "#FFE6B7",
            lecture_item_count: 6,
            serial_order: 2,
            chapter_name: "Questions",
            chapter_id:
              "class_9_learn_englishgrammar_functionalgrammar_questions",
            description: "A sentence worded so as to seek information.",
            chapter_number: 2,
            generic_name: "questions",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_functionalgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_functionalgrammar_questions%2Fmain_image.jpg?alt=media&token=5a72cc89-6376-4236-989c-d0b41fc41637",
          },
          {
            lecture_item_count: 11,
            chapter_id:
              "class_9_learn_englishgrammar_functionalgrammar_sentences",
            description: "Set of words that conveys a statement.",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_functionalgrammar%2Fchapter%2Fclass_9_learn_englishgrammar_functionalgrammar_sentences%2Fmain_image.jpg?alt=media&token=dcad11c1-920e-4c8e-9099-7f277d7e48a4",
            serial_order: 1,
            chapter_number: 1,
            hex_color: "#FFCADB",
            generic_name: "sentences",
            chapter_name: "Sentences",
          },
        ],
      },
      {
        subject_name: "Testing Grammar",
        hex_color: "#bbd2e9",
        updated_on: 1625050283163,
        generic_name: "testing grammar",
        description: "",
        chapters: [
          {
            hex_color: "#FFD3B0",
            lecture_item_count: 4,
            chapter_name: "Dialogue Completion",
            serial_order: 4,
            description:
              "Keep it natural, your brain already knows the correct answer.",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_testinggrammar%2Fchapter%2Fclass_9_learn_englishgrammar_testinggrammar_dialoguecompletion%2Fmain_image.jpg?alt=media&token=f96b5fe6-15f0-4f40-903e-0d2fdba73506",
            chapter_number: 4,
            generic_name: "dialogue completion",
            chapter_id:
              "class_9_learn_englishgrammar_testinggrammar_dialoguecompletion",
          },
          {
            lecture_item_count: 9,
            serial_order: 1,
            chapter_id:
              "class_9_learn_englishgrammar_testinggrammar_editingomission",
            hex_color: "#CDF1C1",
            chapter_name: "Editing and Omission",
            generic_name: "editing & omission",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_testinggrammar%2Fchapter%2Fclass_9_learn_englishgrammar_testinggrammar_editingomission%2Fmain_image.jpg?alt=media&token=4cba9f9a-9ba3-47cc-806c-44b1a050cfc0",
            chapter_number: 1,
            description:
              "Badly written articles are everywhere, you have to correct them all!",
          },
          {
            serial_order: 3,
            lecture_item_count: 4,
            hex_color: "#E4D9FF",
            description:
              "Use your imagination to complete sentences. You will love it!",
            chapter_number: 3,
            chapter_name: "Gap Filling",
            generic_name: "gap filling",
            chapter_id:
              "class_9_learn_englishgrammar_testinggrammar_gapfilling",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_testinggrammar%2Fchapter%2Fclass_9_learn_englishgrammar_testinggrammar_gapfilling%2Fmain_image.jpg?alt=media&token=97f958e1-5b1f-4bb8-8af5-694ec43f2cb4",
          },
          {
            chapter_name: "Sentence Reordering",
            hex_color: "#FAC0D4",
            description:
              "Reordering makes a big difference to the overall emotion.",
            chapter_id:
              "class_9_learn_englishgrammar_testinggrammar_sentencereordering",
            serial_order: 2,
            chapter_number: 2,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_testinggrammar%2Fchapter%2Fclass_9_learn_englishgrammar_testinggrammar_sentencereordering%2Fmain_image.jpg?alt=media&token=d3defbeb-d9be-4096-97fe-bce397bd3722",
            lecture_item_count: 4,
            generic_name: "sentence reordering",
          },
        ],
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishgrammar%2Fsubject%2Fclass_9_learn_englishgrammar_testinggrammar%2Fmain_image.jpg?alt=media&token=47a4488e-fb6e-46ee-8f6c-ac08aff4b5e1",
        subject_id: "class_9_learn_englishgrammar_testinggrammar",
        serial_order: 3,
      },
    ],
  },
  {
    name: "Literature",
    id: "class_9_learn_englishliterature",
    serial_order: 4,
    skippable: false,
    subjects: [
      {
        subject_name: "Beehive Chapters",
        subject_id: "class_9_learn_englishliterature_beehivechapters",
        hex_color: "#bbd2e9",
        serial_order: 2,
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fmain_image.jpg?alt=media&token=754db436-1427-4176-91b4-30c8777cbe1a",
        chapters: [
          {
            description: "Life of genius Scientist Albert Einstein in brief.",
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_atrulybeautifulmind",
            chapter_number: 4,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_atrulybeautifulmind%2Fmain_image.jpg?alt=media&token=ec31577d-492d-42fc-ac9d-18d918678c46",
            serial_order: 5,
            hex_color: "#312727",
            chapter_name: "A Truly Beautiful Mind",
            generic_name: "a truly beautiful mind",
            lecture_item_count: 14,
          },
          {
            generic_name: "if i were you",
            description: "Gerrard's smartness saves his life from an intruder.",
            lecture_item_count: 14,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_ifiwereyou%2Fmain_image.jpg?alt=media&token=b388dbcd-b1af-41f7-a5c9-8ab9e6690e30",
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_ifiwereyou",
            chapter_number: 11,
            serial_order: 13,
            chapter_name: "If I Were You",
            hex_color: "#b7c4fe",
          },
          {
            generic_name: "kathmandu",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_kathmandu%2Fmain_image.jpg?alt=media&token=7a6dfc40-3942-4d90-9a32-85b92161634c",
            chapter_number: 10,
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_kathmandu",
            chapter_name: "Kathmandu",
            lecture_item_count: 9,
            serial_order: 12,
            description:
              "Experiences of Author Vikram Seth in Nepal's capital city.",
            hex_color: "#2aa7f1",
          },
          {
            hex_color: "#f5f9e9",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_mychildhood%2Fmain_image.jpg?alt=media&token=6428be8a-fee8-469e-84dd-b5fdd0ef4176",
            lecture_item_count: 9,
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_mychildhood",
            serial_order: 7,
            chapter_number: 6,
            description:
              'A chapter from President Kalam\'s book "Wings of Fire".',
            chapter_name: "My Childhood",
            generic_name: "my childhood",
          },
          {
            lecture_item_count: 9,
            generic_name: "packing ",
            description:
              "A dog named Montmorency adds twist to the act of packing.",
            hex_color: "#bbd2e9",
            chapter_name: "Packing ",
            chapter_number: 7,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_packing%2Fmain_image.jpg?alt=media&token=b35604d7-7a9d-4182-9926-a7bcc81a7861",
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_packing",
            serial_order: 8,
          },
          {
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_reachforthetop",
            hex_color: "#ed9dc3",
            serial_order: 9,
            description: "Story of Mountain Climber Santosh Yadav's life.",
            generic_name: "reach for the top",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_reachforthetop%2Fmain_image.jpg?alt=media&token=6e9cfd68-dac6-4187-a3de-ff0cd90a8dc2",
            lecture_item_count: 10,
            chapter_name: "Reach for the Top",
            chapter_number: 8,
          },
          {
            lecture_item_count: 6,
            chapter_name: "Maria Sharapova",
            description: "Mantra and journey of tennis player Maria Sharapova.",
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_reachforthetopmariasharapova",
            generic_name: "maria sharapova",
            chapter_number: 8,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_reachforthetopmariasharapova%2Fmain_image.jpg?alt=media&token=ead93df8-2a0d-49bc-a16e-26faf98df27f",
            hex_color: "#bbd2e9",
            serial_order: 10,
          },
          {
            chapter_number: 9,
            lecture_item_count: 10,
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_thebondoflove",
            description:
              "Can a human and a wild animal be friends? Let's find out!",
            generic_name: "the bond of love",
            chapter_name: "The Bond of Love",
            serial_order: 11,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_thebondoflove%2Fmain_image.jpg?alt=media&token=07f467f9-111d-48d2-829c-07fbf7b7e212",
            hex_color: "#f8d8c6",
          },
          {
            chapter_number: 1,
            lecture_item_count: 10,
            generic_name: "the fun they had",
            chapter_name: "The Fun They Had",
            description:
              "Story is set in the year 2157, when children learn at home using a robot.",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_thefuntheyhad%2Fmain_image.jpg?alt=media&token=060b6b99-25d5-4938-aa6e-7e752df5c0d0",
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_thefuntheyhad",
            serial_order: 1,
            hex_color: "#F6BE00",
          },
          {
            hex_color: "#aec3b9",
            description:
              "Journey of how Kezia's feelings change towards her father.",
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_thelittlegirl",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_thelittlegirl%2Fmain_image.jpg?alt=media&token=030326f7-6249-4405-ac79-3d257e4cfd28",
            chapter_name: "The Little Girl",
            generic_name: "the little girl",
            chapter_number: 3,
            lecture_item_count: 9,
            serial_order: 4,
          },
          {
            lecture_item_count: 9,
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_theshehnaiofbismillahkhan",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_theshehnaiofbismillahkhan%2Fmain_image.jpg?alt=media&token=8ced4abd-e794-4b1f-87e3-d8f6fcec881b",
            description:
              "Bharat Ratan Khan needs no introduction. He re-invented an entire genre.",
            chapter_name: "The Shehnai of Bismillah Khan",
            chapter_number: 2,
            generic_name: "the shehnai of bismillah khan",
            hex_color: "#f9e2dc",
            serial_order: 3,
          },
          {
            serial_order: 6,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_thesnakeandthemirror%2Fmain_image.jpg?alt=media&token=5dbff4c3-3b78-45b2-97ec-a46ec828e720",
            lecture_item_count: 8,
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_thesnakeandthemirror",
            description:
              "A curious snake spares life of a doctor for a mirror.",
            hex_color: "#B2BEB5",
            chapter_name: "The Snake and the Mirror",
            chapter_number: 5,
            generic_name: "the snake and the mirror",
          },
          {
            chapter_id:
              "class_9_learn_englishliterature_beehivechapters_thesoundofmusic",
            lecture_item_count: 9,
            description:
              "Story of the life of musical living legend Evelyn Glennie.",
            serial_order: 2,
            hex_color: "#bbd2e9",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivechapters%2Fchapter%2Fclass_9_learn_englishliterature_beehivechapters_thesoundofmusic%2Fmain_image.jpg?alt=media&token=f6ae3861-373b-47e6-a2d5-63e3557b7959",
            chapter_number: 2,
            chapter_name: "The Sound of Music",
            generic_name: "the sound of music",
          },
        ],
        updated_on: 1629803282226,
        description: "",
        generic_name: "beehive chapters",
      },
      {
        description: "",
        hex_color: "#bbd2e9",
        subject_name: "Beehive Poems",
        subject_id: "class_9_learn_englishliterature_beehivepoems",
        chapters: [
          {
            lecture_item_count: 11,
            serial_order: 5,
            chapter_number: 5,
            description: "Phoebe Cary",
            chapter_id:
              "class_9_learn_englishliterature_beehivepoems_alegendofthenorthland",
            chapter_name: "A Legend of the Northland",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivepoems%2Fchapter%2Fclass_9_learn_englishliterature_beehivepoems_alegendofthenorthland%2Fmain_image.jpg?alt=media&token=31b46032-3692-4ab0-af69-a4bfc60c394a",
            generic_name: "a legend of the northland",
            hex_color: "#c4e6ea",
          },
          {
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivepoems%2Fchapter%2Fclass_9_learn_englishliterature_beehivepoems_aslimberdidmyspiritseal%2Fmain_image.jpg?alt=media&token=ac72fd2c-c498-421e-b98f-749c37460e8f",
            description: "William Wordsworth",
            chapter_name: "A Slumber Did My Spirit Seal",
            chapter_number: 10,
            generic_name: "a slumber did my spirit seal",
            hex_color: "#92c5c8",
            chapter_id:
              "class_9_learn_englishliterature_beehivepoems_aslimberdidmyspiritseal",
            serial_order: 10,
            lecture_item_count: 5,
          },
          {
            generic_name: "no men are foreign",
            description: "James Kirkup",
            serial_order: 6,
            chapter_name: "No Men Are Foreign",
            lecture_item_count: 6,
            hex_color: "#fecec5",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivepoems%2Fchapter%2Fclass_9_learn_englishliterature_beehivepoems_nomenareforeign%2Fmain_image.jpg?alt=media&token=d071a44f-1cbd-4080-bdf4-7051bd05d548",
            chapter_number: 6,
            chapter_id:
              "class_9_learn_englishliterature_beehivepoems_nomenareforeign",
          },
          {
            generic_name: "on killing a tree",
            chapter_id:
              "class_9_learn_englishliterature_beehivepoems_onkillingatree",
            lecture_item_count: 10,
            description: "Gieve Patel",
            hex_color: "#dde6fb",
            chapter_number: 8,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivepoems%2Fchapter%2Fclass_9_learn_englishliterature_beehivepoems_onkillingatree%2Fmain_image.jpg?alt=media&token=0f57e179-cab5-42b2-b8a3-ac77adc58542",
            chapter_name: "On Killing a Tree",
            serial_order: 8,
          },
          {
            hex_color: "#b6cfdf",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivepoems%2Fchapter%2Fclass_9_learn_englishliterature_beehivepoems_rainontheroof%2Fmain_image.jpg?alt=media&token=144b608a-68f4-4b52-96b8-e10cc3b806ec",
            description: "Coates Kinney",
            generic_name: "rain on the roof",
            chapter_name: "Rain on the Roof",
            chapter_id:
              "class_9_learn_englishliterature_beehivepoems_rainontheroof",
            lecture_item_count: 6,
            serial_order: 3,
            chapter_number: 3,
          },
          {
            generic_name: "the duck and the kangaroo",
            description: "Edward Lear",
            chapter_name: "The Duck and the Kangaroo",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivepoems%2Fchapter%2Fclass_9_learn_englishliterature_beehivepoems_theduckandthekangaroo%2Fmain_image.jpg?alt=media&token=5b8bf994-0554-4c98-900f-b4084aa45412",
            hex_color: "#e6d7d3",
            chapter_number: 7,
            chapter_id:
              "class_9_learn_englishliterature_beehivepoems_theduckandthekangaroo",
            serial_order: 7,
            lecture_item_count: 3,
          },
          {
            chapter_id:
              "class_9_learn_englishliterature_beehivepoems_thelakeisleofinnisfree",
            chapter_number: 4,
            hex_color: "#cffeec",
            lecture_item_count: 7,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivepoems%2Fchapter%2Fclass_9_learn_englishliterature_beehivepoems_thelakeisleofinnisfree%2Fmain_image.jpg?alt=media&token=62f1951b-58bc-4ded-9cf7-e2daa51b7cca",
            generic_name: "the lake isle of innisfree",
            description: "William Butler Yeats",
            chapter_name: "The Lake Isle of Innisfree",
            serial_order: 4,
          },
          {
            description: "Robert Frost",
            serial_order: 1,
            chapter_id:
              "class_9_learn_englishliterature_beehivepoems_theroadnottaken",
            chapter_number: 1,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivepoems%2Fchapter%2Fclass_9_learn_englishliterature_beehivepoems_theroadnottaken%2Fmain_image.jpg?alt=media&token=79b61344-9724-471e-8280-2bbd025550a7",
            generic_name: "the road not taken",
            lecture_item_count: 6,
            hex_color: "#b1c0bb",
            chapter_name: "The Road Not Taken",
          },
          {
            lecture_item_count: 7,
            chapter_number: 9,
            chapter_id:
              "class_9_learn_englishliterature_beehivepoems_thesnaketrying",
            serial_order: 9,
            hex_color: "#fcfaeb",
            generic_name: "the snake trying",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivepoems%2Fchapter%2Fclass_9_learn_englishliterature_beehivepoems_thesnaketrying%2Fmain_image.jpg?alt=media&token=109a3b11-691c-4498-a42b-02f00d138795",
            chapter_name: "The Snake Trying",
            description: "W.W.E. Ross",
          },
          {
            chapter_number: 2,
            chapter_id: "class_9_learn_englishliterature_beehivepoems_wind",
            description: "Subramania Bharati",
            lecture_item_count: 8,
            chapter_name: "Wind",
            hex_color: "#efe4e4",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivepoems%2Fchapter%2Fclass_9_learn_englishliterature_beehivepoems_wind%2Fmain_image.jpg?alt=media&token=ada267a7-d362-4774-9198-b865314dac27",
            serial_order: 2,
            generic_name: "wind",
          },
        ],
        updated_on: 1679663225000,
        generic_name: "beehive poems",
        serial_order: 3,
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_beehivepoems%2Fmain_image.jpg?alt=media&token=63eb5c4a-0992-454f-9ace-cddd7bb20e27",
      },
      {
        subject_id: "class_9_learn_englishliterature_moments",
        subject_name: "Moments",
        description: "",
        hex_color: "#bbd2e9",
        serial_order: 1,
        generic_name: "moments",
        updated_on: 1624578473064,
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_moments%2Fmain_image.jpg?alt=media&token=f6929bf5-d67b-4e0d-a221-856850dd1a2e",
        chapters: [
          {
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_moments%2Fchapter%2Fclass_9_learn_englishliterature_moments_ahouseisnotahome%2Fmain_image.jpg?alt=media&token=efab83b2-f2b0-47fb-9897-4df9726565e8",
            hex_color: "#a5a7c4",
            generic_name: "a house is not a home",
            chapter_number: 8,
            chapter_id:
              "class_9_learn_englishliterature_moments_ahouseisnotahome",
            description:
              "This story has it all - tragedy, surprizes, hope. Tune in!",
            serial_order: 8,
            chapter_name: "A House Is Not a Home",
            lecture_item_count: 12,
          },
          {
            hex_color: "#f2cfc6",
            chapter_number: 4,
            generic_name: "in the kingdom of fools",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_moments%2Fchapter%2Fclass_9_learn_englishliterature_moments_inthekingdomoffools%2Fmain_image.jpg?alt=media&token=b638b8cb-e407-4c26-8eb5-ecbbb12e1fb8",
            chapter_id:
              "class_9_learn_englishliterature_moments_inthekingdomoffools",
            serial_order: 4,
            description:
              '"Not-so-smart" King and his ministers get to rule, what happens next?!',
            chapter_name: "In the Kingdom of Fools",
            lecture_item_count: 13,
          },
          {
            hex_color: "#d7782c",
            serial_order: 3,
            generic_name: "iswaran the storyteller",
            chapter_name: "Iswaran the Storyteller",
            chapter_id:
              "class_9_learn_englishliterature_moments_iswaranthestoryteller",
            description: "R.K. Laxman's story takes a supernatural twist!",
            chapter_number: 3,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_moments%2Fchapter%2Fclass_9_learn_englishliterature_moments_iswaranthestoryteller%2Fmain_image.jpg?alt=media&token=a4f0a2f0-db79-49d5-8b9f-a80e2b40f792",
            lecture_item_count: 12,
          },
          {
            lecture_item_count: 14,
            chapter_id:
              "class_9_learn_englishliterature_moments_theaccidentaltourist",
            chapter_name: "The Accidental Tourist",
            hex_color: "#feb232",
            chapter_number: 9,
            generic_name: "the accidental tourist",
            serial_order: 9,
            description:
              "Author's story of being vigilant to avoid misadvantures that come his way.",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_moments%2Fchapter%2Fclass_9_learn_englishliterature_moments_theaccidentaltourist%2Fmain_image.jpg?alt=media&token=0d554c25-4cc1-4868-b28a-2b5d6394eb9f",
          },
          {
            lecture_item_count: 11,
            chapter_id:
              "class_9_learn_englishliterature_moments_theadventuresoftoto",
            chapter_name: "The Adventures of Toto",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_moments%2Fchapter%2Fclass_9_learn_englishliterature_moments_theadventuresoftoto%2Fmain_image.jpg?alt=media&token=57ac546c-4cda-4b1e-82dd-185b9f8b897b",
            serial_order: 2,
            generic_name: "the adventures of toto",
            hex_color: "#f8f4d9",
            description:
              "Author Ruskin Bond's epic tale on his grandfather's pet.",
            chapter_number: 2,
          },
          {
            chapter_id: "class_9_learn_englishliterature_moments_thebeggar",
            chapter_number: 10,
            description:
              "From lying for money to working for it, the journey of beggar is astonishing.",
            generic_name: "the beggar",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_moments%2Fchapter%2Fclass_9_learn_englishliterature_moments_thebeggar%2Fmain_image.jpg?alt=media&token=8e8dde5d-2cf4-46b9-b041-405eae3c3910",
            hex_color: "#e7c197",
            chapter_name: "The Beggar",
            lecture_item_count: 12,
            serial_order: 10,
          },
          {
            generic_name: "the happy prince",
            serial_order: 5,
            chapter_id:
              "class_9_learn_englishliterature_moments_thehappyprince",
            chapter_number: 5,
            chapter_name: "The Happy Prince",
            lecture_item_count: 15,
            description:
              "Statue of a Prince and a bird become friends to help the people.",
            hex_color: "#f7feca",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_moments%2Fchapter%2Fclass_9_learn_englishliterature_moments_thehappyprince%2Fmain_image.jpg?alt=media&token=a7f7b20d-7520-4c11-8ffc-6200795f972f",
          },
          {
            chapter_id: "class_9_learn_englishliterature_moments_thelastleaf",
            description:
              "When hope is attached to a leaf, a painter does wonders!",
            generic_name: "the last leaf",
            chapter_name: "The Last Leaf",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_moments%2Fchapter%2Fclass_9_learn_englishliterature_moments_thelastleaf%2Fmain_image.jpg?alt=media&token=7fed6d59-b07e-4af9-a373-70e947698b11",
            chapter_number: 7,
            hex_color: "#fce0b3",
            serial_order: 7,
            lecture_item_count: 10,
          },
          {
            hex_color: "#e2e1f3",
            generic_name: "the lost child",
            lecture_item_count: 12,
            chapter_id: "class_9_learn_englishliterature_moments_thelostchild",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_moments%2Fchapter%2Fclass_9_learn_englishliterature_moments_thelostchild%2Fmain_image.jpg?alt=media&token=538be4b4-1ff7-4b12-a0a0-0ab7ba0bcef9",
            serial_order: 1,
            chapter_name: "The Lost Child",
            chapter_number: 1,
            description: "Story of a kid seperated from the parents in a fair.",
          },
          {
            chapter_id:
              "class_9_learn_englishliterature_moments_weatheringthestorminersama",
            chapter_name: "Weathering the Storm in Ersama",
            chapter_number: 6,
            description:
              "A cyclone in Odisha effects Prashant, but does not break him.",
            generic_name: "weathering the storm in ersama",
            serial_order: 6,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_englishliterature%2Fsubject%2Fclass_9_learn_englishliterature_moments%2Fchapter%2Fclass_9_learn_englishliterature_moments_weatheringthestorminersama%2Fmain_image.jpg?alt=media&token=c2caaf83-f10d-46bc-a671-506e182b97a2",
            lecture_item_count: 11,
            hex_color: "#d6e4fe",
          },
        ],
      },
    ],
  },
  {
    name: "Maths",
    id: "class_9_learn_maths",
    serial_order: 2,
    skippable: true,
    subjects: [
      {
        subject_name: "Maths",
        chapters: [
          {
            lecture_item_count: 42,
            description:
              "Are parallelograms and triangles related? Let’s find out.",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_areasofparallelogramtriangles%2Fmain_image.jpg?alt=media&token=8c022ad1-50ce-4106-896a-b348e3a3902f",
            chapter_number: 9,
            chapter_name: "Areas of Parallelograms and Triangles",
            generic_name: "areas of parallelograms and triangles",
            chapter_id: "class_9_learn_maths_areasofparallelogramtriangles",
            serial_order: 9,
            hex_color: "#77c1a8",
          },
          {
            chapter_name: "Circles",
            chapter_number: 10,
            serial_order: 10,
            chapter_id: "class_9_learn_maths_circles",
            lecture_item_count: 63,
            hex_color: "#ffc46c",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_circles%2Fmain_image.jpg?alt=media&token=d5f1a727-46d0-41c5-9b58-567b35cc129e",
            generic_name: "circles",
            description:
              "Circle! Where it starts and where it ends? Fascinating!",
          },
          {
            description: "Gather your tools. Let’s construct!",
            chapter_id: "class_9_learn_maths_constructions",
            chapter_number: 11,
            serial_order: 11,
            lecture_item_count: 20,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_constructions%2Fmain_image.jpg?alt=media&token=cc297627-ba2c-40ee-bf8f-eca3c866fa3c",
            hex_color: "#8bb8fc",
            chapter_name: "Constructions",
            generic_name: "constructions",
          },
          {
            serial_order: 3,
            chapter_number: 3,
            generic_name: "coordinate geometry",
            lecture_item_count: 16,
            hex_color: "#a68fe1",
            chapter_id: "class_9_learn_maths_coordinategeometry",
            description: "How does Google maps work? Tune in!",
            chapter_name: "Coordinate Geometry",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_coordinategeometry%2Fmain_image.jpg?alt=media&token=511a3207-f439-468c-9ba4-f45f4a399431",
          },
          {
            chapter_id: "class_9_learn_maths_heronsformula",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_heronsformula%2Fmain_image.jpg?alt=media&token=aa18d9a9-75fa-4179-993b-c4710d732bfa",
            lecture_item_count: 27,
            description: "Calculating areas made as easy as 1, 2, 3. Wow!",
            serial_order: 12,
            hex_color: "#a770d5",
            generic_name: "heron's formula",
            chapter_number: 12,
            chapter_name: "Heron's Formula",
          },
          {
            hex_color: "#96c65d",
            description: "Axioms and postulates made fascinating.",
            serial_order: 5,
            chapter_name: "Introduction to Euclid's Geometry",
            chapter_number: 5,
            chapter_id: "class_9_learn_maths_introductiontoeuclidsgeometry",
            lecture_item_count: 21,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_introductiontoeuclidsgeometry%2Fmain_image.jpg?alt=media&token=bc50f3f5-f026-4042-9c44-7579019ad826",
            generic_name: "introduction to euclid's geometry",
          },
          {
            generic_name: "linear equations in two variables",
            chapter_name: "Linear Equations in Two Variables",
            chapter_number: 4,
            serial_order: 4,
            hex_color: "#ffb85a",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_linearequationsintwovariables%2Fmain_image.jpg?alt=media&token=07cfee30-a045-461f-9869-a80d8b25a2f6",
            chapter_id: "class_9_learn_maths_linearequationsintwovariables",
            description:
              "Learn about the bond between graphs and mathematical expressions.",
            lecture_item_count: 34,
          },
          {
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_linesandangles%2Fmain_image.jpg?alt=media&token=42694273-283e-40a6-b160-c3fe9b800421",
            lecture_item_count: 40,
            hex_color: "#bd61a7",
            chapter_number: 6,
            description: "Lines and angles all around, so let's dive in!",
            chapter_name: "Lines and Angles",
            serial_order: 6,
            generic_name: "lines and angles",
            chapter_id: "class_9_learn_maths_linesandangles",
          },
          {
            serial_order: 1,
            chapter_number: 1,
            generic_name: "number systems",
            hex_color: "#b4daff",
            chapter_id: "class_9_learn_maths_numbersystems",
            description:
              "We are never too old to learn about numbers. 1, 2, 3, 4.....",
            lecture_item_count: 59,
            chapter_name: "Number Systems",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_numbersystems%2Fmain_image.jpg?alt=media&token=d95d8935-0b62-46b0-af81-ae5e97d683e6",
          },
          {
            chapter_number: 2,
            hex_color: "#ffc3e1",
            generic_name: "polynomials",
            chapter_id: "class_9_learn_maths_polynomials",
            lecture_item_count: 70,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_polynomials%2Fmain_image.jpg?alt=media&token=a6057429-31ad-49c0-8854-5b6216e2265b",
            description: "Unfolding the mystery of mathematical expressions.",
            chapter_name: "Polynomials",
            serial_order: 2,
          },
          {
            hex_color: "#7e2d41",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_probability%2Fmain_image.jpg?alt=media&token=63a407c4-c9c9-4e04-a9d8-b999735fa03d",
            lecture_item_count: 29,
            chapter_name: "Probability",
            serial_order: 15,
            generic_name: "probability",
            chapter_number: 15,
            description:
              "Chances of you clicking here? Hint: 100%! Test Probability.",
            chapter_id: "class_9_learn_maths_probability",
          },
          {
            chapter_id: "class_9_learn_maths_quadrilaterals",
            generic_name: "quadrilaterals",
            serial_order: 8,
            chapter_number: 8,
            chapter_name: "Quadrilaterals",
            lecture_item_count: 40,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_quadrilaterals%2Fmain_image.jpg?alt=media&token=1098728c-6793-4e6b-bb5d-1ff00df5763d",
            description:
              "From books to televisions, quadrilaterals are everywhere.",
            hex_color: "#81e1ea",
          },
          {
            chapter_name: "Statistics",
            serial_order: 14,
            hex_color: "#ee7d94",
            chapter_id: "class_9_learn_maths_statistics",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_statistics%2Fmain_image.jpg?alt=media&token=6d043a68-94f8-4b9c-87e7-d73b313df512",
            generic_name: "statistics",
            chapter_number: 14,
            lecture_item_count: 53,
            description:
              "What should be the run-rate to win a match? Ask Statistics.",
          },
          {
            serial_order: 13,
            hex_color: "#bccd6c",
            description:
              "Study of painting walls and filling petrol. Just kidding.",
            generic_name: "surface areas and volumes",
            lecture_item_count: 105,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_surfaceareasandvolumes%2Fmain_image.jpg?alt=media&token=aa727adc-dd40-46b9-95a6-c099301ffccf",
            chapter_name: "Surface Areas and Volumes",
            chapter_number: 13,
            chapter_id: "class_9_learn_maths_surfaceareasandvolumes",
          },
          {
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_maths%2Fsubject%2Fclass_9_learn_maths%2Fchapter%2Fclass_9_learn_maths_triangles%2Fmain_image.jpg?alt=media&token=beeabc0b-2987-4fbe-b18d-fd045bf66a8d",
            lecture_item_count: 54,
            hex_color: "#d25149",
            serial_order: 7,
            description:
              "Favourite of engineers, strongest of them all: Triangles.",
            chapter_number: 7,
            chapter_id: "class_9_learn_maths_triangles",
            chapter_name: "Triangles",
            generic_name: "triangles",
          },
        ],
        description:
          "The subject is much more than numbers, shapes and patterns. It teaches us art of problem-solving with logic.",
        updated_on: 1631097697291,
        serial_order: 2,
        subject_id: "class_9_learn_maths",
        generic_name: "maths",
        mini_thumbnail_url: null,
        main_thumbnail_url: null,
      },
    ],
  },
  {
    name: "Science",
    id: "class_9_learn_science",
    serial_order: 1,
    skippable: false,
    subjects: [
      {
        hex_color: "#bbd2e9",
        description:
          "The study of everything that is, or was , alive — whether it's a plant, animal or microorganism.",
        main_thumbnail_url: null,
        chapters: [
          {
            chapter_number: 3,
            hex_color: "#C8EFF3",
            chapter_id:
              "class_9_learn_science_biology_diversityinlivingorganisms",
            chapter_name: "Diversity in Living Organisms",
            lecture_item_count: 49,
            description:
              "Organised study of the living beings for better understanding.",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_biology%2Fchapter%2Fclass_9_learn_science_biology_diversityinlivingorganisms%2Fmain_image.jpg?alt=media&token=abbfb84d-86db-44be-a5de-e9c5170a870f",
            generic_name: "diversity in living organisms",
            serial_order: 3,
          },
          {
            description:
              "Title of this chapter says it all, but let us give you a hint: Cells",
            generic_name: "the fundamental unit of life",
            chapter_number: 1,
            chapter_name: "The Fundamental Unit of Life",
            hex_color: "#DCE7B5",
            lecture_item_count: 35,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_biology%2Fchapter%2Fclass_9_learn_science_biology_thefundamentalunitoflife%2Fmain_image.jpg?alt=media&token=0615472f-769c-409f-936e-fbce29a57d3e",
            serial_order: 1,
            chapter_id:
              "class_9_learn_science_biology_thefundamentalunitoflife",
          },
          {
            serial_order: 2,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_biology%2Fchapter%2Fclass_9_learn_science_biology_tissues%2Fmain_image.jpg?alt=media&token=db09bcb7-4986-4caf-89cb-0a7f83082e4e",
            generic_name: "tissues",
            hex_color: "#F8CCDB",
            chapter_name: "Tissues",
            chapter_number: 2,
            lecture_item_count: 38,
            chapter_id: "class_9_learn_science_biology_tissues",
            description:
              "Cell groups similar in structure and perform a specific function.",
          },
          {
            hex_color: "#FBC5B4",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_biology%2Fchapter%2Fclass_9_learn_science_biology_whydowefallill%2Fmain_image.jpg?alt=media&token=7c734781-5517-4b3a-af06-18094500886d",
            generic_name: "why do we fall ill",
            chapter_id: "class_9_learn_science_biology_whydowefallill",
            chapter_name: "Why Do We Fall Ill",
            lecture_item_count: 32,
            description:
              "The question that has wondered humans since eternity. ",
            serial_order: 4,
            chapter_number: 4,
          },
        ],
        generic_name: "biology",
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_biology%2Fmain_image.jpg?alt=media&token=62f36bac-56df-43c4-8bfb-66f15130b0e1",
        subject_name: "Biology",
        serial_order: 3,
        updated_on: 1629091188695,
        subject_id: "class_9_learn_science_biology",
      },
      {
        generic_name: "chemistry",
        chapters: [
          {
            description: "Fundamental particles that make the world around us.",
            lecture_item_count: 33,
            serial_order: 3,
            chapter_id: "class_9_learn_science_chemistry_atomsmolecules",
            chapter_number: 3,
            hex_color: "#FFE4B2",
            chapter_name: "Atoms and Molecules",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_chemistry%2Fchapter%2Fclass_9_learn_science_chemistry_atomsmolecules%2Fmain_image.jpg?alt=media&token=96157ef7-d7a7-4f72-a264-550635bfc74b",
            generic_name: "atoms and molecules",
          },
          {
            hex_color: "#D5EAFF",
            chapter_number: 2,
            lecture_item_count: 37,
            chapter_id: "class_9_learn_science_chemistry_ismatterarounduspure",
            generic_name: "is matter around us pure",
            chapter_name: "Is Matter Around Us Pure",
            serial_order: 2,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_chemistry%2Fchapter%2Fclass_9_learn_science_chemistry_ismatterarounduspure%2Fmain_image.jpg?alt=media&token=bf949dac-c657-42ab-ac47-42ad1b67a709",
            description:
              'Understand what "pure" is in context of matter around us.',
          },
          {
            lecture_item_count: 34,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_chemistry%2Fchapter%2Fclass_9_learn_science_chemistry_matterinoursurroundings%2Fmain_image.jpg?alt=media&token=86660568-7632-4d88-97e2-8a9defb5d3a1",
            chapter_name: "Matter in Our Surroundings",
            description:
              "Explore what the world around us is made of. Hint: Matter",
            chapter_id:
              "class_9_learn_science_chemistry_matterinoursurroundings",
            generic_name: "matter in our surroundings",
            chapter_number: 1,
            serial_order: 1,
            hex_color: "#D2D4FF",
          },
          {
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_chemistry%2Fchapter%2Fclass_9_learn_science_chemistry_structureoftheatom%2Fmain_image.jpg?alt=media&token=dc61e2c6-6748-4385-a731-d0a71616f338",
            chapter_number: 4,
            generic_name: "structure of the atom",
            hex_color: "#FFC7DE",
            lecture_item_count: 46,
            serial_order: 4,
            chapter_name: "Structure of the Atom",
            chapter_id: "class_9_learn_science_chemistry_structureoftheatom",
            description:
              "Dive into the universe of electrons, neutrons, orbits, and what not. ",
          },
        ],
        subject_name: "Chemistry",
        main_thumbnail_url:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_chemistry%2Fmain_image.jpg?alt=media&token=43262369-b229-47c3-8d2c-5eac67faf3be",
        description:
          "Chemistry is a branch of natural science that deals principally with the properties of substances, the changes they undergo, and the natural laws that describe these changes.",
        hex_color: "#B39C4D",
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_chemistry%2Fmain_image.jpg?alt=media&token=b087b061-f8f6-4cdd-9f92-f8fe6a2476fa",
        subject_id: "class_9_learn_science_chemistry",
        serial_order: 2,
        mini_thumbnail_url:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_chemistry%2Fmini_image.jpg?alt=media&token=dfac4686-5003-403f-95f8-3c92fbcea904",
        updated_on: 1679917696000,
      },
      {
        description: "",
        subject_name: "Natural Resources",
        serial_order: 4,
        hex_color: "#bbd2e9",
        chapters: [
          {
            lecture_item_count: 41,
            chapter_number: 2,
            chapter_name: "Improvement In Food Resources",
            generic_name: "improvement in food resources",
            hex_color: "#bbd2e9",
            serial_order: 2,
            description: "",
            chapter_id:
              "class_9_learn_science_naturalresources_improvementinfoodresources",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_naturalresources%2Fchapter%2Fclass_9_learn_science_naturalresources_improvementinfoodresources%2Fmain_image.jpg?alt=media&token=8d31dec5-c7cf-41b8-a868-2be506f7c4b2",
          },
          {
            chapter_name: "Natural Resources",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_naturalresources%2Fchapter%2Fclass_9_learn_science_naturalresources_naturalresources%2Fmain_image.jpg?alt=media&token=a85a1c65-2b94-4908-96f1-13faa16d2628",
            chapter_id:
              "class_9_learn_science_naturalresources_naturalresources",
            lecture_item_count: 39,
            chapter_number: 1,
            hex_color: "#bbd2e9",
            serial_order: 1,
            generic_name: "natural resources",
            description: "",
          },
        ],
        subject_id: "class_9_learn_science_naturalresources",
        updated_on: 1629803501920,
        generic_name: "natural resources",
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_naturalresources%2Fmain_image.jpg?alt=media&token=7e66c069-0cf1-4421-b10c-9823e0e20f6d",
      },
      {
        mini_thumbnail_url:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_physics%2Fmini_image.jpg?alt=media&token=b1994c06-ac67-4686-8246-1a83670b1007",
        subject_id: "class_9_learn_science_physics",
        chapters: [
          {
            serial_order: 2,
            chapter_name: "Force and Laws of Motion",
            lecture_item_count: 46,
            chapter_number: 2,
            chapter_id: "class_9_learn_science_physics_forcelawsofmotion",
            generic_name: "force and laws of motion",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_physics%2Fchapter%2Fclass_9_learn_science_physics_forcelawsofmotion%2Fmain_image.jpg?alt=media&token=2586b81f-926f-45f0-af90-5a169a04df62",
            description: "Rules that dictate any moving body in our universe!",
            hex_color: "#FFCCD9",
          },
          {
            generic_name: "gravitation",
            chapter_name: "Gravitation",
            description:
              "Force that keeps us grounded to this planet and more!",
            lecture_item_count: 51,
            chapter_number: 3,
            chapter_id: "class_9_learn_science_physics_gravitation",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_physics%2Fchapter%2Fclass_9_learn_science_physics_gravitation%2Fmain_image.jpg?alt=media&token=38757ea9-8df1-4bab-9856-722053232c62",
            serial_order: 3,
            hex_color: "#C5E2FF",
          },
          {
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_physics%2Fchapter%2Fclass_9_learn_science_physics_motion%2Fmain_image.jpg?alt=media&token=eb961181-b4ea-4fce-bfdf-76b1a0c42ea7",
            generic_name: "motion",
            hex_color: "#C7F7DF",
            lecture_item_count: 54,
            chapter_number: 1,
            description: "The act of moving makes everything possible.",
            chapter_name: "Motion",
            chapter_id: "class_9_learn_science_physics_motion",
            serial_order: 1,
          },
          {
            serial_order: 5,
            chapter_name: "Sound",
            lecture_item_count: 64,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_physics%2Fchapter%2Fclass_9_learn_science_physics_sound%2Fmain_image.jpg?alt=media&token=c41e1512-ab92-4b38-b462-e577c7235940",
            generic_name: "sound",
            description:
              "Learn about the type of energy created by vibrations!",
            chapter_id: "class_9_learn_science_physics_sound",
            hex_color: "#DED4F7",
            chapter_number: 5,
          },
          {
            hex_color: "#FFECBE",
            chapter_number: 4,
            chapter_id: "class_9_learn_science_physics_workenergy",
            lecture_item_count: 52,
            chapter_name: "Work and Energy",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_physics%2Fchapter%2Fclass_9_learn_science_physics_workenergy%2Fmain_image.jpg?alt=media&token=abb81109-3a46-4a7a-a21d-7cc58f2e584c",
            description:
              "Explore the mystery around definition of work and what causes it.",
            generic_name: "work and energy",
            serial_order: 4,
          },
        ],
        hex_color: "#fdf7e5",
        updated_on: 1713973182000,
        description:
          "Science about why objects move the way they do and how? What are the laws that dictate their motion?",
        generic_name: "physics",
        serial_order: 1,
        main_thumbnail_url:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_physics%2Fmain_image.jpg?alt=media&token=1b4347d8-dd42-4eeb-9077-73a18f78edb5",
        subject_name: "Physics",
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_science%2Fsubject%2Fclass_9_learn_science_physics%2Fmain_image.jpg?alt=media&token=617e4266-a4ac-4960-9c5e-a1344e161729",
      },
    ],
  },
  {
    name: "Social Science",
    id: "class_9_learn_socialscience",
    serial_order: 3,
    skippable: false,
    subjects: [
      {
        updated_on: 1631622463584,
        hex_color: "#bbd2e9",
        chapters: [
          {
            chapter_name: "Constitutional Design",
            chapter_id:
              "class_9_learn_socialscience_civics_constitutionaldesign",
            serial_order: 3,
            chapter_number: 3,
            lecture_item_count: 22,
            hex_color: "#fed095",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_civics%2Fchapter%2Fclass_9_learn_socialscience_civics_constitutionaldesign%2Fmain_image.jpg?alt=media&token=daaf04b5-352c-4e28-8566-b432a2e591f8",
            description: "Rules that make our country a nation of people.",
            generic_name: "constitutional design",
          },
          {
            generic_name: "democracy in the contemporary world",
            serial_order: 1,
            chapter_id:
              "class_9_learn_socialscience_civics_democracyinthecontemporaryworld",
            chapter_name: "Democracy in the Contemporary World",
            hex_color: "#91b9a9",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_civics%2Fchapter%2Fclass_9_learn_socialscience_civics_democracyinthecontemporaryworld%2Fmain_image.jpg?alt=media&token=b0a9756a-ee99-4efb-975f-bca92fc37af5",
            description: "By the people, of the people, for the people.",
            chapter_number: 1,
            lecture_item_count: 21,
          },
          {
            serial_order: 6,
            chapter_name: "Democratic Rights",
            chapter_id: "class_9_learn_socialscience_civics_democraticrights",
            description:
              "Benefits we get as a citizen of India, know yours now!",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_civics%2Fchapter%2Fclass_9_learn_socialscience_civics_democraticrights%2Fmain_image.jpg?alt=media&token=ccb6167c-b821-4aeb-8731-3994720fabd0",
            generic_name: "democratic rights",
            lecture_item_count: 25,
            chapter_number: 6,
            hex_color: "#e3cbca",
          },
          {
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_civics%2Fchapter%2Fclass_9_learn_socialscience_civics_electoralpolitics%2Fmain_image.jpg?alt=media&token=5a2185d1-12a7-4082-9bd2-f327621a5fa0",
            lecture_item_count: 30,
            chapter_name: "Electoral Politics",
            chapter_number: 4,
            generic_name: "electoral politics",
            hex_color: "#fbfed2",
            description:
              "Marketing at its best! True or False, right or wrong?",
            serial_order: 4,
            chapter_id: "class_9_learn_socialscience_civics_electoralpolitics",
          },
          {
            chapter_name: "What is Democracy? Why Democracy?",
            description: "Think of this chapter as a pamphlet of Democracy.",
            serial_order: 2,
            generic_name: "what is democracy? why democracy?",
            hex_color: "#92cdfe",
            chapter_id:
              "class_9_learn_socialscience_civics_whatisdemocracywhydemocracy",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_civics%2Fchapter%2Fclass_9_learn_socialscience_civics_whatisdemocracywhydemocracy%2Fmain_image.jpg?alt=media&token=5c0cd781-890f-48cc-8562-9a36e699864a",
            lecture_item_count: 23,
            chapter_number: 2,
          },
          {
            generic_name: "working of institutions",
            hex_color: "#fef3dc",
            serial_order: 5,
            lecture_item_count: 24,
            chapter_name: "Working of Institutions",
            chapter_id:
              "class_9_learn_socialscience_civics_workingoninstitutions",
            chapter_number: 5,
            description: "Glimpses into the functioning of a government.",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_civics%2Fchapter%2Fclass_9_learn_socialscience_civics_workingoninstitutions%2Fmain_image.jpg?alt=media&token=4b2d7cdb-d94b-4653-88c7-98aedb84198e",
          },
        ],
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_civics%2Fillustration.jpg?alt=media&token=6ba0c4ad-770b-4574-a46c-03cc89648dc1",
        subject_name: "Civics",
        generic_name: "civics",
        description:
          "Not just the study of the rights and duties of citizenship. Civics teaches us to be a more informed person.",
        serial_order: 2,
        main_thumbnail_url: null,
        subject_id: "class_9_learn_socialscience_civics",
      },
      {
        description:
          "The branch of knowledge concerned with the production, consumption, and transfer of wealth.",
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_economics%2Fmain_image.jpg?alt=media&token=fd442e3b-abe0-4711-8793-ccdf896bdc7e",
        chapters: [
          {
            hex_color: "#fbfed3",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_economics%2Fchapter%2Fclass_9_learn_socialscience_economics_foodsecurityinindia%2Fmain_image.jpg?alt=media&token=7be39585-dd7f-4054-9b65-e9a5710d9945",
            generic_name: "food security in india",
            chapter_id:
              "class_9_learn_socialscience_economics_foodsecurityinindia",
            chapter_name: "Food Security in India",
            serial_order: 4,
            description: "Food is necessity, but does India have enough of it?",
            chapter_number: 4,
            lecture_item_count: 21,
          },
          {
            chapter_number: 2,
            serial_order: 2,
            chapter_id:
              "class_9_learn_socialscience_economics_peopleasresource",
            description: "A resource India has plenty of: Human Resource!",
            generic_name: "people as resource",
            chapter_name: "People as Resource",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_economics%2Fchapter%2Fclass_9_learn_socialscience_economics_peopleasresource%2Fmain_image.jpg?alt=media&token=b9180b45-76c1-42e4-bf96-745b62afdb53",
            lecture_item_count: 22,
            hex_color: "#c3e3fe",
          },
          {
            serial_order: 3,
            chapter_id:
              "class_9_learn_socialscience_economics_povertyasachallenge",
            lecture_item_count: 19,
            chapter_name: "Poverty as a Challenge",
            chapter_number: 3,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_economics%2Fchapter%2Fclass_9_learn_socialscience_economics_povertyasachallenge%2Fmain_image.jpg?alt=media&token=6d88191b-b31f-4005-a955-f66b6546aec3",
            description:
              "Poverty is something that we have to eradicate. Help needed!",
            generic_name: "poverty as a challenge",
            hex_color: "#e2fdf4",
          },
          {
            chapter_number: 1,
            hex_color: "#fee7cd",
            chapter_name: "The Story of Village Palampur",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_economics%2Fchapter%2Fclass_9_learn_socialscience_economics_thestoryofvillagepalampur%2Fmain_image.jpg?alt=media&token=49448322-d75f-4d75-83db-0f259753378d",
            description: "A glimpses into a typical rural Indian village.",
            serial_order: 1,
            chapter_id:
              "class_9_learn_socialscience_economics_thestoryofvillagepalampur",
            lecture_item_count: 21,
            generic_name: "the story of village palampur",
          },
        ],
        generic_name: "economics",
        subject_id: "class_9_learn_socialscience_economics",
        main_thumbnail_url:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_economics%2Fmain_image.jpg?alt=media&token=4bfc6578-9ab8-49f2-a835-a24fe6e9bc25",
        serial_order: 4,
        updated_on: 1626679041250,
        mini_thumbnail_url:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_economics%2Fmini_image.jpg?alt=media&token=c4602f5a-dcfd-48c5-8f42-716fcc1b2b37",
        subject_name: "Economics",
        hex_color: "#bbd2e9",
      },
      {
        hex_color: "#bbd2e9",
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_geography%2Fillustration.jpg?alt=media&token=4bb3852d-29a8-442b-bd37-e7446439a443",
        description:
          "The study of the natural features of the earth's surface, including topography, climate, soil, vegetation, etc, and man's response to them.",
        subject_id: "class_9_learn_socialscience_geography",
        generic_name: "geography",
        serial_order: 3,
        updated_on: 1625485418631,
        main_thumbnail_url: null,
        chapters: [
          {
            generic_name: "climate",
            chapter_name: "Climate",
            serial_order: 4,
            chapter_number: 4,
            lecture_item_count: 24,
            description:
              "Spring, summers, monsoons, winters... which one is your favourite?",
            hex_color: "#bffef5",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_geography%2Fchapter%2Fclass_9_learn_socialscience_geography_climate%2Fmain_image.jpg?alt=media&token=ed73cf1d-5434-4156-a6a6-aac4daa51662",
            chapter_id: "class_9_learn_socialscience_geography_climate",
          },
          {
            serial_order: 3,
            chapter_number: 3,
            chapter_name: "Drainage",
            hex_color: "#fee5cd",
            lecture_item_count: 16,
            chapter_id: "class_9_learn_socialscience_geography_drainage",
            description: "Nope! Not the sewage drainage, we mean rivers.",
            generic_name: "drainage",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_geography%2Fchapter%2Fclass_9_learn_socialscience_geography_drainage%2Fmain_image.jpg?alt=media&token=63d1780c-05da-4e0f-9a5c-10964505297c",
          },
          {
            serial_order: 1,
            chapter_name: "India - Size & Location",
            generic_name: "india - size & location",
            chapter_number: 1,
            description:
              "We know our home address, but what's the address for India?",
            chapter_id:
              "class_9_learn_socialscience_geography_indiasizelocation",
            hex_color: "#f0d5cc",
            lecture_item_count: 11,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_geography%2Fchapter%2Fclass_9_learn_socialscience_geography_indiasizelocation%2Fmain_image.jpg?alt=media&token=9035b168-e71d-4744-9bc1-be421d051e93",
          },
          {
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_geography%2Fchapter%2Fclass_9_learn_socialscience_geography_naturalvegetationwildlife%2Fmain_image.jpg?alt=media&token=5c78fcaa-35e1-4c40-a5c3-bb053c0c9f33",
            description:
              "India's biodiversity is something to marvel at. Welcome!",
            serial_order: 5,
            chapter_id:
              "class_9_learn_socialscience_geography_naturalvegetationwildlife",
            hex_color: "#fde4e4",
            generic_name: "natural vegetation & wildlife",
            lecture_item_count: 20,
            chapter_number: 5,
            chapter_name: "Natural Vegetation and Wildlife",
          },
          {
            chapter_name: "Physical Features of India",
            description:
              "Mountains, deserts, plains, beaches... we have it all!",
            generic_name: "physical features of india",
            serial_order: 2,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_geography%2Fchapter%2Fclass_9_learn_socialscience_geography_physicalfeaturesofindia%2Fmain_image.jpg?alt=media&token=4555a605-b829-4255-af77-9ba51126b1c7",
            chapter_id:
              "class_9_learn_socialscience_geography_physicalfeaturesofindia",
            lecture_item_count: 19,
            chapter_number: 2,
            hex_color: "#99cc99",
          },
          {
            chapter_name: "Population",
            chapter_id: "class_9_learn_socialscience_geography_population",
            lecture_item_count: 22,
            hex_color: "#ffffb2",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_geography%2Fchapter%2Fclass_9_learn_socialscience_geography_population%2Fmain_image.jpg?alt=media&token=e62b0577-6967-4e59-b699-08d16c9ddb3b",
            generic_name: "population",
            chapter_number: 6,
            serial_order: 6,
            description:
              "Seven billion people on this planet! You are the one!",
          },
        ],
        subject_name: "Geography",
      },
      {
        serial_order: 1,
        description:
          "The study of the past – specifically the people, societies, events and problems of the past – as well as our attempts to understand them.",
        generic_name: "history",
        chapters: [
          {
            description:
              "Fashion repeats itself! But what is the real journey?",
            chapter_name: "Clothing : A Social History",
            chapter_id:
              "class_9_learn_socialscience_history_clothingasocialhistory",
            chapter_number: 8,
            hex_color: "#f1c08a",
            serial_order: 8,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_history%2Fchapter%2Fclass_9_learn_socialscience_history_clothingasocialhistory%2Fmain_image.jpg?alt=media&token=7e544b27-4758-439a-a163-5845b5468eef",
            generic_name: "clothing : a social history",
            lecture_item_count: 15,
          },
          {
            lecture_item_count: 11,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_history%2Fchapter%2Fclass_9_learn_socialscience_history_forestsocietycolonialism%2Fmain_image.jpg?alt=media&token=03224e9d-ca2d-4160-b8e7-87c33dc93354",
            chapter_number: 4,
            description:
              "British came to India for its resources. But which ones?",
            chapter_name: "Forest Society and Colonialism",
            hex_color: "#dba9a9",
            chapter_id:
              "class_9_learn_socialscience_history_forestsocietycolonialism",
            serial_order: 4,
            generic_name: "forest society and colonialism",
          },
          {
            description:
              "Sports come with a long and glorious history. Cricket is special.",
            serial_order: 7,
            chapter_id:
              "class_9_learn_socialscience_history_historysportthestoryofcricket",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_history%2Fchapter%2Fclass_9_learn_socialscience_history_historysportthestoryofcricket%2Fmain_image.jpg?alt=media&token=d74e1192-41c3-4a69-b6bc-683dc6296ad0",
            chapter_name: "History and Sports",
            chapter_number: 7,
            generic_name: "history and sports",
            hex_color: "#ffa500",
            lecture_item_count: 12,
          },
          {
            chapter_id:
              "class_9_learn_socialscience_history_nazismtheriseofhitler",
            description:
              "World War II revealed what impact ideology can have on people.",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_history%2Fchapter%2Fclass_9_learn_socialscience_history_nazismtheriseofhitler%2Fmain_image.jpg?alt=media&token=ff58cb67-f9cb-4f6a-9d4b-cdd6de15df45",
            chapter_number: 3,
            generic_name: "nazism and the rise of hitler",
            lecture_item_count: 14,
            chapter_name: "Nazism and the Rise of Hitler",
            hex_color: "#a4a400",
            serial_order: 3,
          },
          {
            generic_name: "pastoralists in modern world",
            lecture_item_count: 10,
            hex_color: "#abadaa",
            chapter_id:
              "class_9_learn_socialscience_history_pastoralistsinmodernworld",
            description:
              "Milk, cheese, wool, butter - Their contribution sounds tasty!",
            chapter_number: 5,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_history%2Fchapter%2Fclass_9_learn_socialscience_history_pastoralistsinmodernworld%2Fmain_image.jpg?alt=media&token=53ed8f88-3b83-447b-8e4a-c1af9237d7c0",
            chapter_name: "Pastoralists in Modern World",
            serial_order: 5,
          },
          {
            generic_name: "peasants and farmers",
            serial_order: 6,
            description: "Backbone of agricultural economy and food security.",
            chapter_name: "Peasants and Farmers",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_history%2Fchapter%2Fclass_9_learn_socialscience_history_peasantsfarmers%2Fmain_image.jpg?alt=media&token=e84455e5-eaa1-4311-b639-792b5fb988ca",
            chapter_id: "class_9_learn_socialscience_history_peasantsfarmers",
            chapter_number: 6,
            hex_color: "#fae6ca",
            lecture_item_count: 15,
          },
          {
            hex_color: "#ff4c4c",
            lecture_item_count: 14,
            serial_order: 2,
            description:
              "Europe has been at the epicenter of revolutions, tune in!",
            chapter_name: "Socialism in Europe",
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_history%2Fchapter%2Fclass_9_learn_socialscience_history_socialismineuropetherussianrevolution%2Fmain_image.jpg?alt=media&token=33e5e96f-6a4f-46fa-98e3-afbdce48a106",
            chapter_number: 2,
            generic_name: "socialism in europe",
            chapter_id:
              "class_9_learn_socialscience_history_socialismineuropetherussianrevolution",
          },
          {
            chapter_number: 1,
            chapter_name: "The French Revolution",
            hex_color: "#d4d2fe",
            lecture_item_count: 16,
            description:
              "How was World's first democracy born? Hint: This Revolution",
            chapter_id:
              "class_9_learn_socialscience_history_thefrenchrevolution",
            serial_order: 1,
            illustration_art:
              "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_history%2Fchapter%2Fclass_9_learn_socialscience_history_thefrenchrevolution%2Fmain_image.jpg?alt=media&token=8e484933-2eba-4f4c-907c-d79b50582c9f",
            generic_name: "the french revolution",
          },
        ],
        subject_name: "History",
        updated_on: 1631593893004,
        main_thumbnail_url:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_history%2Fmain_image.jpg?alt=media&token=9860ac92-cdf4-4d87-9a5f-2fe202abdb7c",
        hex_color: "#a09d90",
        illustration_art:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_history%2Fillustration.jpg?alt=media&token=936c4694-c77e-447b-8f76-2e7105108eae",
        subject_id: "class_9_learn_socialscience_history",
        mini_thumbnail_url:
          "https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2Fclass_9%2Fscope%2Fclass_9_learn%2Fcategory%2Fclass_9_learn_socialscience%2Fsubject%2Fclass_9_learn_socialscience_history%2Fmini_image.jpg?alt=media&token=29007a3f-8dd8-47e8-9d21-4ebf8ebd49c4",
      },
    ],
  },
];

export const dummyGrades = [
  { id: "class_9", label: "Class 9" },
  { id: "class_10", label: "Class 10" },
];

interface DropDownItem1Props {
  items: { id: string; label: string }[];
  activeKey: string | null;
  onChange: (value: string) => void;
  placeholder?: string;
  shouldSelect?: boolean;
}
function DropDownItem1(props: DropDownItem1Props) {
  if (props.shouldSelect)
    return (
      <div>
        <div>
          <h3 className="mb-2 ml-1 text-xs font-bold text-gray-500">
            {props.placeholder}
          </h3>
        </div>
        <Select
          className="w-full"
          classNames={{
            trigger: "!rounded border bg-gray-50 !h-auto !min-h-[unset] !py-1",
            popoverContent: "!rounded !p-0 !bg-gray-50",
            listbox: "whitespace-nowrap",
            value: "text-sm",
          }}
          placeholder={props.placeholder ?? "Choose Item"}
          selectedKeys={props.activeKey ? [props.activeKey] : []}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
        >
          {props.items.map((item) => (
            <SelectItem
              classNames={{ base: "!rounded-none text-sm" }}
              key={item.id}
            >
              {item.label}
            </SelectItem>
          ))}
        </Select>
      </div>
    );
  return (
    <div>
      <div>
        <h3 className="mb-2 ml-1 text-xs font-bold text-gray-500">
          {props.placeholder}
        </h3>
      </div>
      <div className="flex items-center gap-2 flex-wrap">
        {props.items.map((item) => (
          <div
            key={item.id}
            className={
              "bg-gray-100 rounded-full py-1 px-3 text-sm cursor-pointer " +
              (props.activeKey === item.id ? " !bg-[#a10e0e] !text-white" : "")
            }
            onClick={() => {
              props.onChange(item.id);
            }}
          >
            <span>{item.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
  // return (
  //   <Dropdown
  //     placement="bottom-start"
  //     classNames={{
  //       content:
  //         "!rounded-sm !p-0 overflow-hidden !min-w-[120px] w-full !bg-white shadow-sm",
  //       base: "max-h-[300px] overflow-auto",
  //     }}
  //     offset={1}
  //   >
  //     <DropdownTrigger className="text-sm !py-1 px-2.5 pl-3 border bg-gray-50 rounded flex items-center justify-center gap-2 !h-[auto]">
  //       <Button>
  // {props.items.find((c) => c.id === props.activeKey)?.label ? (
  //   <span>
  //     {props.items.find((c) => c.id === props.activeKey)?.label}
  //   </span>
  // ) : (
  //   <span className="text-black text-opacity-30">
  //     {props.placeholder}
  //   </span>
  // )}
  //         <FaCaretDown />
  //       </Button>
  //     </DropdownTrigger>
  //     <DropdownMenu
  //       aria-label="Example with disabled actions"
  //       disabledKeys={["edit", "delete"]}
  //       classNames={{
  //         base: "!p-0",
  //       }}
  //     >
  //       {props.items.map((item) => (
  //         <DropdownItem
  //           className="!rounded-none text-sm"
  //           classNames={{ base: "!rounded-none" }}
  //           key={item.id}
  //           onClick={() => {
  //             props.onChange(item.id);
  //           }}
  //         >
  //           {item.label}
  //         </DropdownItem>
  //       ))}
  //       {/* <DropdownItem key="new">New file</DropdownItem>
  //         <DropdownItem key="copy">Copy link</DropdownItem>
  //         <DropdownItem key="edit">Edit file</DropdownItem>
  //         <DropdownItem key="delete" className="text-danger" color="danger">
  //           Delete file
  //         </DropdownItem> */}
  //     </DropdownMenu>
  //   </Dropdown>
  // );
}

interface ChooseChapterProps {
  onChapterSelect: (chapter: Chapter | undefined) => void;
}
export default function ChooseChapter(props: ChooseChapterProps) {
  //   const { data } = useFetchGrades();
  const [selected, setSelected] = useState<Record<string, string | null>>({
    grade: "class_9",
    category: null,
    subject: null,
    chapter: null,
  });

  const onChange = (key: string) => (value: string | null) => {
    if (key === "chapter") {
      setSelected((c) => ({
        ...c,
        [key]: value,
      }));
      const categoryItem = dummyCategories.find(
        (c) => c.id === selected.category
      );
      if (!categoryItem) return null;
      if (categoryItem.skippable) {
        return props.onChapterSelect(
          categoryItem.subjects[0].chapters?.find((c) => c.chapter_id === value)
        );
      }
      const subjectItem = categoryItem.subjects.find(
        (c) => c.subject_id === selected.subject
      );
      if (!subjectItem) return null;
      props.onChapterSelect(
        subjectItem.chapters?.find((c) => c.chapter_id === value)
      );
    } else {
      setSelected((c) => ({
        ...c,
        [key]: value,
        chapter: null,
      }));
      props.onChapterSelect(undefined);
    }
  };

  const categoryItems = useMemo(() => {
    return dummyCategories.map((c) => ({
      id: c.id,
      label: c.name,
    }));
  }, []);

  const subjectItems = useMemo(() => {
    const categoryItem = dummyCategories.find(
      (c) => c.id === selected.category
    );
    if (!categoryItem || categoryItem.skippable) return null;
    return categoryItem.subjects.map((c) => ({
      id: c.subject_id,
      label: c.subject_name,
    }));
  }, [selected]);

  const chapterItems = useMemo(() => {
    const categoryItem = dummyCategories.find(
      (c) => c.id === selected.category
    );
    if (!categoryItem) return null;

    if (categoryItem.skippable) {
      return categoryItem.subjects[0].chapters.map((c) => ({
        id: c.chapter_id,
        label: c.chapter_name,
      }));
    }

    const subjectItem = categoryItem.subjects.find(
      (c) => c.subject_id === selected.subject
    );
    if (!subjectItem) return null;
    return subjectItem.chapters.map((c) => ({
      id: c.chapter_id,
      label: c.chapter_name,
    }));
  }, [selected]);

  useEffect(() => {
    if (!chapterItems) props.onChapterSelect(undefined);
  }, [chapterItems, props]);

  return (
    <div className="">
      <DropDownItem1
        activeKey={selected.grade}
        items={dummyGrades}
        onChange={onChange("grade")}
        placeholder="Select Grade"
      />
      <hr className="my-5" />
      <DropDownItem1
        items={categoryItems}
        activeKey={selected.category}
        onChange={onChange("category")}
        placeholder="Select Category"
      />
      {subjectItems && (
        <>
          <hr className="my-5" />
          <DropDownItem1
            activeKey={selected.subject}
            onChange={onChange("subject")}
            placeholder="Select Subject"
            items={subjectItems}
          />
        </>
      )}
      {chapterItems && (
        <>
          <hr className="my-5" />
          <DropDownItem1
            activeKey={selected.chapter}
            onChange={onChange("chapter")}
            placeholder="Select Chapter"
            items={chapterItems}
            shouldSelect={true}
          />
        </>
      )}
    </div>
  );
}
