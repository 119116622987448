import React, { useEffect, useRef } from "react";
import { Transforms } from "slate";
import { ReactEditor, useReadOnly, useSlate } from "slate-react";

export default function QuestionElement({
  children,
  element,
  attributes,
}: any) {
  const qNRef = useRef<HTMLInputElement>(null);
  const questionRef = useRef<HTMLInputElement>(null);
  const editor = useSlate();
  const readonly = useReadOnly();

  useEffect(() => {
    if (!qNRef.current || !questionRef.current) return () => {};

    qNRef.current.value = element.q_no ?? "";
    questionRef.current.value = element.question ?? "";
  }, [element, readonly]);

  const handleChangeTitle = (key: "q_no" | "question") => () => {
    // @ts-ignore
    const path = ReactEditor.findPath(editor, element);
    // Adjust the path to point to the root
    const rootPath = path;

    let customElement: any = { type: "question" };

    // Replace the current element with the custom element

    if (key === "q_no") {
      customElement = {
        type: "question",
        q_no: qNRef.current?.value ?? "Q1",
      };
    } else if (key === "question") {
      customElement = {
        type: "question",
        question: questionRef.current?.value ?? "",
      };
    }

    Transforms.setNodes(editor, customElement, { at: rootPath });
  };

  if (readonly) {
    return (
      <div>
        <div
          className="grid grid-cols-[30px_1fr] gap-3 my-4"
          key={element.question}
        >
          <div>
            <span>{element.q_no ?? ""}</span>
          </div>
          <div>
            <h5 className="font-semibold mb-1">{element.question ?? ""}</h5>
            {children}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-[50px_1fr] gap-3 my-4 shadow-sm">
      <div contentEditable={false}>
        <input
          ref={qNRef}
          type="text"
          placeholder="Q1"
          onChange={handleChangeTitle("q_no")}
          className="py-1.5 px-2 w-full bg-white bg-opacity-70 placeholder:text-black placeholder:text-opacity-20"
        />
        {/* <span>Q7</span> */}
      </div>
      <div>
        <div contentEditable={false}>
          <input
            ref={questionRef}
            placeholder="Ask Question here..."
            type="text"
            onChange={handleChangeTitle("question")}
            className="py-1.5 px-3 w-full bg-white bg-opacity-70 placeholder:text-black placeholder:text-opacity-20"
          />
        </div>
        {/* <h5 className="font-semibold mb-1">What is the scope of physics?</h5> */}
        {/* <p>
          Physics is a science that studies the structure of matter and how the
          universe’s fundamental building blocks interact. Its scope ranges from
          the infinitesimally small objects studied using the principles of
          quantum mechanics to the entirety of the cosmos, explored using
          general relativity.
        </p> */}
        <div className="bg-white bg-opacity-70 mt-1" {...attributes}>
          {children}
        </div>
      </div>
    </div>
  );
}
