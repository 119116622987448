import { API_QUERIES } from "../../../constants/api_queries";
// import { Category } from "@/store/cms";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Article,
  Category,
  insertLevelsAndGetFirestorePath,
  LevelItem,
  toggleArticleStatus,
} from "../cms";
import { useMemo } from "react";

export const useCMS = () => {};

export const useFetchGrades = () => {
  const queryData = useQuery({
    queryKey: API_QUERIES.grades,
    queryFn: async () => {
      return [
        { id: "class_9", label: "Class 9" },
        { id: "class_10", label: "Class 10" },
      ];
    },
  });

  return queryData;
};

interface FetchCategoryOptions {
  grade_id: string | null | undefined;
}
export const useFetchCategory = ({ grade_id }: FetchCategoryOptions) => {
  const queryData = useQuery({
    queryKey: API_QUERIES.categories(grade_id),
    queryFn: async ({ queryKey }) => {
      const [, grade_id] = queryKey;

      if (!grade_id) throw new Error("Grade Id is required.");

      const categories = await Category.fetchCategories({ grade_id });

      return Category.transformCategoriesToLevels(categories);
    },
  });

  return queryData;
};

export const useFetchHierarchy = ({ grade_id }: FetchCategoryOptions) => {
  const queryData = useQuery({
    queryKey: API_QUERIES.hierarchy(grade_id),
    queryFn: async ({ queryKey }) => {
      const [, grade_id] = queryKey;

      if (!grade_id) throw new Error("Grade Id is required.");

      const snapshot = await require("../../../firebase-config")
        .db.collection("articles")
        .doc(grade_id)
        .get();

      return snapshot.data()?.hierarchy as LevelItem;
    },
  });

  const allArticles = useMemo(() => {
    const _data = queryData.data;

    if (!_data) return [];

    const _allArticles: LevelItem[] = [];

    const traverse = (
      node: LevelItem,
      levelIndex: number,
      parentPath = "",
      keyForPath = "slug"
    ) => {
      // Create the current node's path by appending the current node's slug to the parent path
      const currentPath = parentPath
        ? `${parentPath}/${node[keyForPath]}`
        : node[keyForPath];

      // If the node has children, traverse them
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          traverse(child, levelIndex + 1, currentPath); // Pass the updated path down
        });
      }

      // Add the current node with its URL and level index
      const _node = {
        ...node,
        level_index: levelIndex,
        url: currentPath,
        grade_id: grade_id,
      };
      _allArticles.push(_node);
    };

    traverse(_data, 1, "", "id");

    return _allArticles;
  }, [queryData.data, grade_id]);

  return {
    ...queryData,
    allArticles,
  };
};

interface CreateArticleOptions {
  nodes: any;
  selectionPath: string[];
  selectedGrade: LevelItem;
  newHierarchy: any;
}
export const useCreateArticle = () => {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (options: CreateArticleOptions) => {
      const { collectionPath, docId } = insertLevelsAndGetFirestorePath([
        // Using grade id, but other's slug only
        options.selectedGrade.id,
        ...options.selectionPath,
      ]);

      await require("../../../firebase-config")
        .db.collection(collectionPath)
        .doc(docId)
        .set({
          nodes: options.nodes,
          selectionPath: options.selectionPath,
          selectedGrade: options.selectedGrade,
        });

      await require("../../../firebase-config")
        .db.collection("articles")
        .doc(options.newHierarchy.id)
        .set(
          {
            hierarchy: options.newHierarchy,
          },
          { merge: true }
        );

      return;
    },
    onSettled: (_, error, options) => {
      qc.invalidateQueries({
        queryKey: API_QUERIES.hierarchy(options.selectedGrade.id),
      });
    },
    onError: (error) => {
      console.log("error - ", error);
    },
  });
};

export const useGetArticle = (urlString) => {
  return useQuery({
    queryKey: API_QUERIES.article(urlString),
    queryFn: async ({ queryKey }) => {
      const [, url] = queryKey;

      if (!url) throw new Error("URL is required.");

      return await Article.fetchByUrl(url);
    },
    retry: false,
  });
};

interface ToggleArticleStatusOptions {
  grade_id: string;
  url: string;
  status: "published" | "unpublished";
}
export const useToggleArticleStatus = (
  options?: UseMutationOptions<void, Error, ToggleArticleStatusOptions>
) => {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({
      grade_id,
      url,
      status,
    }: ToggleArticleStatusOptions) => {
      await Article.toggleStatus(grade_id, url, status);

      return;
    },
    onSettled: (_, error, options) => {
      qc.invalidateQueries({
        queryKey: API_QUERIES.hierarchy(options.grade_id),
      });
    },
    onError: (error) => {
      console.log("error - ", error);
    },
    ...(options ?? {}),
  });
};
