import React, { useEffect, useRef, useState } from "react";
import { FaCaretDown } from "react-icons/fa6";
import { motion } from "framer-motion";
import { Transforms } from "slate";
import { ReactEditor, useReadOnly, useSlate } from "slate-react";

export default function AccordionElement({
  element,
  attributes,
  children,
}: any) {
  const [isOpen, setIsOpen] = useState(true);
  const editor = useSlate();
  const inputRef = useRef<HTMLInputElement>(null);
  const readonly = useReadOnly();

  useEffect(() => {
    if (readonly) setIsOpen(false);
  }, [readonly]);

  useEffect(() => {
    if (!inputRef.current) return () => {};
    inputRef.current.value = element.title ?? "";
  }, [element]);

  const handleChangeTitle = () => {
    // @ts-ignore
    const path = ReactEditor.findPath(editor, element);
    // Adjust the path to point to the root
    const rootPath = path;

    // Replace the current element with the custom element
    let customElement: any = {
      type: "accordion",
      title: inputRef.current?.value ?? "",
    };

    Transforms.setNodes(editor, customElement, { at: rootPath });
  };

  if (readonly) {
    return (
      <div>
        <div
          onClick={() => {
            setIsOpen((c) => !c);
          }}
          className="w-full py-2 rounded px-4 bg-[#801615] cursor-pointer text-white font-extrabold text-lg flex items-center justify-between"
        >
          <p>{element.title}</p>
          <span>
            <FaCaretDown
              style={{
                transform: `rotate(${isOpen ? -180 : 0}deg)`,
                transition: "transform 0.25s",
              }}
            />
          </span>
        </div>
        <motion.div
          initial={false}
          animate={{
            height: isOpen ? "auto" : 0,
            overflow: isOpen ? "unset" : "hidden",
            opacity: isOpen ? 1 : 0,
          }}
          transition={{ duration: 0.25 }}
        >
          <div className="p-4 border border-t-0 pb-8">
            {children}
            {/* <table className="w-full [&_td]:p-3 [&_td]:text-[#801615] [&_td]:font-semibold">
            <tr>
              <td>Air Composition Properties</td>
              <td>Anemometer</td>
            </tr>
            <tr>
              <td>Cloud Formation</td>
              <td>Difference Between Cyclone And Hurricane</td>
            </tr>
            <tr>
              <td>How To Make A Windmill</td>
              <td>Trade Winds</td>
            </tr>
            <tr>
              <td>Types of Wind</td>
              <td>Unit of Humidity</td>
            </tr>
            <tr>
              <td>Humidity</td>
              <td>Layers of Atmosphere</td>
            </tr>
          </table> */}
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="shadow-sm">
      <div
        className="flex items-center justify-between gap-1 bg-white"
        contentEditable={false}
      >
        <div className="flex-1">
          <input
            className="py-1.5 px-5 w-full bg-white"
            type="text"
            placeholder="Enter Accordion Title"
            ref={inputRef}
            onChange={handleChangeTitle}
          />
        </div>
        <div
          className="p-3 cursor-pointer transition-all"
          style={{
            transform: `rotate(${isOpen ? 180 : 0}deg)`,
          }}
          onClick={() => setIsOpen((c) => !c)}
        >
          <FaCaretDown />
        </div>
      </div>
      <motion.div
        initial={false}
        animate={{
          height: isOpen ? "auto" : 0,
          overflow: isOpen ? "unset" : "hidden",
          opacity: isOpen ? 1 : 0,
        }}
        transition={{ duration: 0.25 }}
        style={
          {
            //   overflow: isOpen ? "unset" : "hidden",
          }
        }
      >
        <div className="bg-lightPrimary" {...attributes}>
          {children}
        </div>
      </motion.div>
    </div>
  );
}
